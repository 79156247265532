import React from "react";
import { GymPackages } from "../components/GymPackages";
import BookingCalendar from "../components/BookingCalendar";
import { PoolPackages } from "../components/PoolPackages";
import  CourtBooking  from "../components/BookingCourt";

export const Booking = ({ type }) => {
  return (
    <div>
      {type === "gym" ? (
        <GymPackages />
      ) : type === "pool" ? (
        <PoolPackages />
      ) : type === "badminton"? (
        <CourtBooking type={type} />
      ) : type === "cricket"? (
        <CourtBooking type={type} />
      ) :(
        <BookingCalendar />
      )}
    </div>
  );
};
