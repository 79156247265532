import React from "react";
import { Table } from "antd";
import generatePDF from 'react-to-pdf';
import { ApiClient } from "../utils/ApiClient";
import toast from "react-hot-toast";

export const OrderHistory = () => {
    const targetRef = React.useRef(null);

    const handleClickPaid = async (e) => {
        try {
            const response = await ApiClient.get(`/order/getOrder/${e.id}`);
            //console.log(response);
        } catch (error) {
            //console.log(error);
            toast.error("Failed to update payment status");
        }
    }

    const [orderHistory, setOrderHistory] = React.useState([]);

    React.useEffect(() => {
        fetchData();
    }
    , []);

    const fetchData = async () => {
        try {
            const response = await ApiClient.get("/order/getOrders");
            setOrderHistory(response.data);
            //console.log(response.data);
        } catch (error) {
            //console.log(error);
            toast.error("Failed to fetch order history");
        }
    };

 
  const columns = [
    {
        title: "Index",
        dataIndex: "index",
        key: "index",
        render: (text, record, index) => {
            return <span>{index + 1}</span>;
        },
    },
    {
      title: "Customer Name",
      dataIndex: "orderedBy",
      key: "name",
    },
    {
      title:"Total",
      dataIndex:"total",
      key:"total",
      render:(text,record)=>{
        return <span>Rs.{text}/=</span>;
      }
    },
    {
      title: "Service Charge",
      dataIndex: "serviceCharge",
      key: "serviceCharge",
      render: (text, record) => {
        return <span>Rs.{text}/=</span>;
      },

    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
        render: (discount, record) => {
            return <span>Rs.{discount}/=</span>;
        },
    },
    {
      title: "SubTotal",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (text, record) => {
        return <span>Rs.{text}/=</span>;
      },
    },

    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (paidAmount, record) => {
        return <span>Rs.{paidAmount}/=</span>;
      },
    },
    // {
    //   title: "Balance",
    //   dataIndex: "balance",
    //   key: "balance",
    //   render: (balance, record) => {
    //     return <span>Rs.{paidAmount-subTotal}/=</span>;
    //   },
    // },
    {
        title: "Status",
        dataIndex: "paymentStatus",
        key: "paymentStatus",
        render: (text,dataIndex) => {
            return <button id={dataIndex._id} value={text} onClick={(e)=>handleClickPaid(e.target)} >{text}</button>;
        },
    },
    {
      title: "Cashier Name",
      dataIndex: "cashiersName",
      key: "cashiersName",
    },
    {
      title: "Order Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => {
        return <span>{new Date(text).toDateString()}</span>;
      },
    },
  ];
  return (
    <div>
      <Table dataSource={orderHistory} columns={columns} />;
     
    </div>
  );
};
