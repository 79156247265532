import { Calendar, momentLocalizer } from "react-big-calendar";
import React, { useEffect, useState } from "react";
import moment from "moment";
// import events from "./BookingCalendar/events";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./BookingCalendar.css";
import { Button, Modal, Select } from "antd";
import { Form, Input, InputNumber } from "antd";
import { Row, Col } from "antd";
import { ApiClient } from "../utils/ApiClient";
import { useSelector } from "react-redux";
import { Radio } from "antd";
import { DatePicker, Space } from "antd";
import { getUser } from "../store/slices/userSlice";
import dayjs from "dayjs";
import toast from "react-hot-toast";

const BookingCalendar = ({ type }) => {
  moment.locale("en-GB");
  const localizer = momentLocalizer(moment);
  const [selectedDate, setSelectedDate] = useState();
  const [eventsData, setEventsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [componentSize, setComponentSize] = useState("large");
  const [reservationType, setReservationType] = useState("One Time Package");
  const [noOfChild, setNoOfChild] = useState(0);
  const [noOfAdult, setNoOfAdult] = useState(0);
  const [dateNew, setDateNew] = useState(null);
  const [checkInTime, setCheckInTime] = useState(null);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [total, setTotal] = useState(0);
  const [oneTimePackages, setOneTimePackages] = useState([]);
  const [contactNumber, setContactNumber] = useState(null);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [optionsIn, setOptionsIn] = useState([]);
  const [optionsOut, setOptionsOut] = useState([]);
  const [startHour, setStartHour] = useState(6);
  const [customerName, setCustomerName] = useState("");
  const [calendarData, setCalendarData] = useState([]);
  const [subscriptionDetails, setSubscriptionDetails] = useState("");
  const [plainOptions, setPlainOptions] = useState([
    { label: "One Time Package", value: "One Time Package" },
    {
      label: "Subscription Package",
      value: "Subscription Package",
      disabled: true,
    },
  ]);
  const user = useSelector(getUser);
  const getPhone = useSelector((state) => state?.user?.user?.phone);
  const [form] = Form.useForm();
  const [options, setOptions] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [valueIn, setValueIn] = useState();
  const [valueOut, setValueOut] = useState();
  const [userId, setUserId] = useState("");

  const fetchUsers = async () => {
    const response = await ApiClient.get("/user/getUsers");
    setUserDetails(response.data);
  };

  useEffect(() => {
    const optionsList = userDetails.map((user) => (
      <Select.Option key={user?.membershipNumber} value={user._id}>
        {(user.membershipNumber
          ? "(" + user.membershipNumber + ")" + " "
          : "") + user.email}
      </Select.Option>
    ));
    setOptions(optionsList);
  }, [userDetails]);

  const plainOptionsCustomer = [
    { label: "Yes", value: "Yes" },
    {
      label: "No",
      value: "No",
    },
  ];

  useEffect(() => {
    fetchData();
    getOnetimePackages();
    fetchUsers();
  }, []);

  useEffect(() => {
    if (subscriptionDetails?.poolSubscription?.subscriptionStatus == "Active") {
      setIsSubscriptionActive(true);
      setPlainOptions([
        { label: "One Time Package", value: "One Time Package" },
        {
          label: "Subscription Package",
          value: "Subscription Package",
          disabled: false,
        },
      ]);
    } else {
      setIsSubscriptionActive(false);
      setPlainOptions([
        { label: "One Time Package", value: "One Time Package" },
        {
          label: "Subscription Package",
          value: "Subscription Package",
          disabled: true,
        },
      ]);
    }
  }, [subscriptionDetails]);

  const resetFormValues = () => {
    // setContactNumber(user?.phone);
    setNoOfAdult(0);
    setNoOfChild(0);
    setCheckInTime(null);
    setCheckOutTime(null);
    setDateNew(null);
    setSelectedDate();
    setTotal(0);
    setReservationType("One Time Package");
  };

  const onChange = (date, dateString) => {
    //console.log(dateString);
    setSelectedDate(date);
    setDateNew(dateString);
  };

  useEffect(() => {
    if (checkInTime !== null) {
      handleChangeI(valueIn);
      handleChangeO(valueOut);
    }
  }, [dateNew]);

  const handleChangeI = async (value) => {
    setValueIn(value);
    setStartHour(value + 2);
    const newDate = new Date(dateNew);
    const newDate1 = new Date(newDate.setHours(value));
    const newDate2 = new Date(newDate1.setMinutes(0));
    setCheckInTime(newDate2);
  };

  const handleChangeO = (value) => {
    setValueOut(value);
    const newDate = new Date(dateNew);
    const newDate1 = new Date(newDate.setHours(value));
    const newDate2 = new Date(newDate1.setMinutes(0));
    setCheckOutTime(newDate2);
  };

  const fetchData = async () => {
    setCalendarData([]);
    try {
      const response = await ApiClient.get("/pool-event/");
      //console.log(response.data.events)
      const formattedEvents = response.data.events.map((event) => ({
        id: event._id,
        title: event.title || "Booked",
        start: new Date(event.startTime),
        end: new Date(event.endTime),
        qty: event.noOfAdult + event.noOfChild,
      }));
      setEventsData(formattedEvents);
    } catch (error) {
      //console.log(error);
      toast.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    let tempArray = [];
    eventsData.map((event) => {
      const splittedEvents = splitEvent(event, 1);
      splittedEvents.map((splittedEvent) => {
        const temp = tempArray.find(
          (item) => item.start.getTime() == splittedEvent.start.getTime()
        );
        if (temp) {
          temp.qty += event.qty;
          temp.title = `${temp.qty} Booked`;
          tempArray = tempArray.filter(
            (item) => item.start.getTime() !== splittedEvent.start.getTime()
          );
          tempArray.push(temp);
        } else {
          splittedEvent.title = `${event.qty} Booked`;
          tempArray.push(splittedEvent);
        }
      });
    });
    setCalendarData(tempArray);
  }, [eventsData]);

  const splitEvent = (originalEvent, durationInHours) => {
    const hourDuration = durationInHours * 60 * 60 * 1000; // Convert hours to milliseconds

    const events = [];
    let currentStart = new Date(originalEvent.start);

    while (currentStart < originalEvent.end) {
      const currentEnd = new Date(currentStart.getTime() + hourDuration);
      if (currentEnd > originalEvent.end) {
        currentEnd.setTime(originalEvent.end.getTime());
      }

      events.push({
        ...originalEvent,
        start: new Date(currentStart),
        end: new Date(currentEnd),
      });

      currentStart = new Date(currentEnd);
    }

    return events;
  };

  const onChange1 = ({ target: { value } }) => {
    setReservationType(value);
  };

  const [customerType, setCustomerType] = useState("Yes");

  const onChange2 = ({ target: { value } }) => {
    setCustomerType(value);
    setCustomerName("");
    setUserId("");
    form.setFieldValue("user", "");
  };

  const generateOptionsIn = () => {
    const options = [];
    for (let hour = 6; hour <= 22; hour++) {
      const label = `${hour.toString().padStart(2, "0")}:00`;
      options.push({ value: hour, label });
    }
    return options;
  };

  const generateOptionsOut = () => {
    const options = [];
    for (let hour = startHour; hour <= 23; hour++) {
      const label = `${hour.toString().padStart(2, "0")}:00`;
      options.push({ value: hour, label });
    }
    return options;
  };

  useEffect(() => {
    setOptionsOut(generateOptionsOut());
  }, [startHour]);

  useState(() => {
    setOptionsIn(generateOptionsIn());
  }, []);

  const formats = {
    dayHeaderFormat: (date, culture, localizer) =>
      localizer.format(date, "dddd, MMMM Do", culture), // Format day header
    timeGutterFormat: (date, culture, localizer) =>
      localizer.format(date, "HH:mm", culture), // Format time as HH:mm
  };

  const today = moment().startOf("day");
  const startOfWeek = today.clone().startOf("isoWeek");

  const onFormLayoutChange = ({ size }) => {
    setComponentSize("large");
  };

  const handleSubmit = async () => {
    if (user?.role === "admin") {
      // setOpen(false);
      if (reservationType === "One Time Package") {
        let eventData = {
          startTime: checkInTime,
          endTime: checkOutTime,
          noOfAdult: noOfAdult,
          noOfChild: noOfChild,
          subscription: null,
          contactNumber: contactNumber,
        };

        if (customerType === "Yes") {
          eventData.userName = customerName;
        } else {
          eventData.createdBy = userId;
        }

        ApiClient.post("/pool-event/", eventData)
          .then((res) => {
            //console.log(res);
            setOpen(false);
            resetFormValues();
            fetchData();
            toast.success("Reservation Successful");
          })
          .catch((error) => {
            //console.log(error);
            toast.error(`Failed to make reservation ${error.message}`);
          });
      } else {
        ApiClient.post("/pool-event/", {
          createdBy: userId,
          startTime: checkInTime,
          endTime: checkOutTime,
          noOfAdult: 0,
          noOfChild: 0,
          subscription: user.poolSubscription._id,
          contactNumber: form.getFieldValue("contactNumber"),
        })
          .then((res) => {
            //console.log(res);
            setOpen(false);
            resetFormValues();
            fetchData();
            toast.success("Reservation Successful");
          })
          .catch((error) => {
            //console.log(error);
            toast.error(`Failed to make reservation ${error.message}`);
          });
      }
    } else {
      if (reservationType === "One Time Package") {
        let eventData = {
          startTime: checkInTime,
          endTime: checkOutTime,
          noOfAdult: noOfAdult,
          noOfChild: noOfChild,
          subscription: null,
          contactNumber: contactNumber,
          createdBy : user?._id
        };


        ApiClient.post("/pool-event/", eventData)
          .then((res) => {
            //console.log(res);
            setOpen(false);
            resetFormValues();
            fetchData();
            toast.success("Reservation Successful");
          })
          .catch((error) => {
            //console.log(error);
            toast.error(`Failed to make reservation ${error.message}`);
          });
      } else {
        ApiClient.post("/pool-event/", {
          createdBy: user?._id,
          startTime: checkInTime,
          endTime: checkOutTime,
          noOfAdult: 0,
          noOfChild: 0,
          subscription: user.poolSubscription._id,
          contactNumber: form.getFieldValue("contactNumber"),
        })
          .then((res) => {
            //console.log(res);
            setOpen(false);
            resetFormValues();
            fetchData();
            toast.success("Reservation Successful");
          })
          .catch((error) => {
            //console.log(error);
            toast.error(`Failed to make reservation ${error.message}`);
          });
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    resetFormValues();
    setSelectedDate(null);
  };

  const getOnetimePackages = async () => {
    try {
      const response = await ApiClient.get("/pool-packages/getOneTimePackages");
      setOneTimePackages(response.data);
      //console.log(response.data);
    } catch (error) {
      //console.log(error);
      toast.error("Failed to fetch data");
    }
  };

  const handleAdultChange = (value) => {
    setNoOfAdult(value);
  };

  const handelChildChange = (value) => {
    setNoOfChild(value);
  };

  useEffect(() => {
    calculateTotal({ noOfChild, noOfAdult });
  }, [noOfAdult, noOfChild, checkInTime, checkOutTime, subscriptionDetails]);

  const fetchUserDetails = async (userId) => {
    try {
      const response = await ApiClient.get(`/user/gymsubcription/${userId}`);
      setSubscriptionDetails(response.data);
      console.log(response.data);
    } catch (error) {
      //console.log(error);
      toast.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    if (user?.role === "admin" && userId) {
      fetchUserDetails(userId);
    } else {
      user?._id && fetchUserDetails(user?._id);
      form.setFieldValue("user", user?.name);
    }
  }, [userId, user]);

  const calculateTotal = ({ noOfChild, noOfAdult }) => {
    console.log(subscriptionDetails?.gymSubscription?.subscriptionStatus);
    if (checkInTime && checkOutTime) {
      const numberOfHours = Math.abs(checkOutTime - checkInTime) / 36e5;
      if (
        subscriptionDetails?.gymSubscription?.subscriptionStatus == "Active"
      ) {
        let temp = 0;
        if (noOfChild > 0) {
          temp += noOfChild * oneTimePackages[0]?.rate;
        }
        if (noOfAdult > 0) {
          temp +=
            (noOfAdult - 1) * oneTimePackages[2]?.rate +
            oneTimePackages[1]?.rate;
        }
        setTotal((temp / 2) * numberOfHours);
      } else {
        setTotal(() => {
          return (
            ((noOfChild * oneTimePackages[0]?.rate +
              noOfAdult * oneTimePackages[2]?.rate) /
              2) *
            numberOfHours
          );
        });
      }
    } else {
      setTotal(0);
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-center">
        <Button
          type="primary"
          onClick={() => {
            setOpen(true);
            form.setFieldValue("user", user?.name);
            form.setFieldValue("contactNumber", user?.getPhone);
          }}
        >
          + New Reservation
        </Button>
      </div>
      <Calendar
        views={["day", "week"]}
        selectable={false}
        localizer={localizer}
        defaultView="week"
        events={calendarData && calendarData}
        defaultDate={startOfWeek.toDate()}
        style={{ height: "100vh" }}
        // onSelectEvent={(event) => alert(event.title)}
        // onSelectSlot={handleSelect}
        longPressThreshold={20}
        // onSelecting={slot => false}
        // components={{()=><div>hello</div>}}

        step={60}
        formats={formats} // Apply custom time slot label format
        min={new Date(0, 0, 0, 6, 0, 0)} // Minimum time (5:00 AM)
        max={new Date(0, 0, 0, 22, 0, 0)} // Maximum time (10:00 PM)
        timeslots={1}
        className="no-row-spacing"
      />
      <Modal
        title="Make a Reservation for Swimming Pool"
        centered
        open={open}
        onOk={() => handleSubmit()}
        onCancel={() => handleClose(false)}
        okButtonProps={{
          disabled:
            reservationType === "One Time Package"
              ? !checkInTime ||
                !checkOutTime ||
                !selectedDate ||
                noOfAdult + noOfChild === 0 ||
                (contactNumber && contactNumber?.toString()?.length != 10)
              : !checkInTime ||
                !checkOutTime ||
                !selectedDate ||
                (contactNumber && contactNumber?.toString()?.length != 10),
        }}
      >
        <div>
          <Row>
            <Col span={24} offset={0}>
              <Form
                form={form}
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 18,
                }}
                layout="horizontal"
                labelAlign="left"
                initialValues={{
                  size: componentSize,
                }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
                style={{
                  maxWidth: 600,
                }}
              >
                {user?.role === "admin" && (
                  <div>
                    <Form.Item label="New Customer?">
                      <Radio.Group
                        options={plainOptionsCustomer}
                        onChange={onChange2}
                        value={customerType}
                      />
                    </Form.Item>
                    {customerType === "Yes" && (
                      <Form.Item label="User Name" name="user">
                        <Input
                          style={{ width: "100%" }}
                          value={customerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                        />
                      </Form.Item>
                    )}
                    {customerType === "No" && (
                      <Form.Item label="User Name" name="user">
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select a person"
                          value={userId}
                          onChange={(value) => {
                            setUserId(value);
                          }}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {options}
                        </Select>
                      </Form.Item>
                    )}
                  </div>
                )}
                {user?.role !== "admin" && (
                  <Form.Item label="User Name" name="user">
                    <Input
                      style={{ width: "100%" }}
                      value={user?.name}
                      readOnly
                    />
                  </Form.Item>
                )}
                <Form.Item label="Contact Number" name="contactNumber">
                  <Input
                    style={{ width: "100%" }}
                    value={contactNumber}
                    placeholder="07XXXXXXXX"
                    maxLength={10}
                    type="number"
                    onChange={(e) => setContactNumber(e.target.value)}
                  />
                </Form.Item>
                {((user?.role === "admin" && customerType === "No") ||
                  user?.role !== "admin") && (
                  <Form.Item label="Select Package">
                    <Radio.Group
                      options={plainOptions}
                      onChange={onChange1}
                      value={reservationType}
                    />
                  </Form.Item>
                )}

                {reservationType === "One Time Package" && (
                  <>
                    <Form.Item label="No of Childrens">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        value={noOfChild}
                        onChange={(value) => handelChildChange(value)}
                      />
                    </Form.Item>

                    <Form.Item label="No of Adults">
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        value={noOfAdult}
                        onChange={(value) => handleAdultChange(value)}
                      />
                    </Form.Item>
                  </>
                )}

                <Form.Item label="Date">
                  <DatePicker
                    value={selectedDate}
                    onChange={onChange}
                    style={{ width: "100%" }}
                    disabledDate={(current) =>
                      current && current < moment().startOf("day")
                    }
                  />
                </Form.Item>

                <Form.Item label="Check-In">
                  <Select
                    defaultValue="Select Time"
                    style={{ width: "100%" }}
                    onChange={handleChangeI}
                    value={checkInTime && checkInTime.getHours()}
                    options={optionsIn}
                  />
                </Form.Item>
                <Form.Item label="Check-Out">
                  <Select
                    defaultValue="Select Time"
                    style={{ width: "100%" }}
                    onChange={handleChangeO}
                    value={checkOutTime && checkOutTime.getHours()}
                    options={optionsOut}
                    disabled={!checkInTime}
                  />
                </Form.Item>
              </Form>
            </Col>
            {reservationType == "One Time Package" && (
              <Col span={24} offset={0}>
                <div className="d-flex align-items-end flex-column">
                  {user?.gymSubscription?.subscriptionStatus == "Active" && (
                    <h6>Active Gym Member Discount : 75 LKR/Hour</h6>
                  )}
                  <h4>{`Total : ${total}`}</h4>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </Modal>
    </div>
  );
};
export default BookingCalendar;
