import React, { useEffect, useState } from "react";
import { Card, Col, Row, Statistic } from "antd";
import CountUp from "react-countup";
import { UserOutlined } from "@ant-design/icons";
import { ApiClient } from "../../utils/ApiClient";
const formatter = (value) => <CountUp end={value} separator="," />;
const AdminHome = () => {
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await ApiClient.get("/user/summary")
      .then((response) => {
        // console.log(response.data);
        setSummary(response.data);
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Card bordered={false} title="Swimming Pool">
          <Statistic
            value={summary?.swimmingPoolCount}
            formatter={formatter}
            suffix={<UserOutlined />}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card bordered={false} title="Badminton Court">
          {
            <h4
              style={
                summary?.badmintonCourt == "Available"
                  ? { color: "green" }
                  : { color: "red" }
              }
            >
              {summary?.badmintonCourt}
            </h4>
          }
        </Card>
      </Col>
      <Col span={12}>
        <Card bordered={false} title="Cricket Court">
          <h4
            style={
              summary?.cricketCourt == "Available"
                ? { color: "green" }
                : { color: "red" }
            }
          >
            {summary?.cricketCourt}
          </h4>
        </Card>
      </Col>
    </Row>
  );
};

export default AdminHome;
