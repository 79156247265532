import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router";

export const BookingCard = ({ data }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("pool");
    }

    const changepath = (data) => {
      navigate(`/booking${data.path}`);
  }
  
  return (
    <div>
      <div className="row d-flex justify-content-center p-2 shadow m-4 rounded">
        <div className="col-md-6 d-flex justify-content-center my-2">
          <img src={data.pic} alt="..." className="rounded" style={{height:"250px"}} />
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-center">
          <h3>{data.name}</h3>
          <p>{data.description}</p>
          <h4></h4>
          
            <Button className="bg-primary text-white font-weight-bold " onClick={()=>{changepath(data)}} >Book Now!</Button>
        
        </div>
      </div>
    </div>
  );
};
