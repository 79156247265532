import React from "react";
import { WhyChoose } from "../components/WhyChoose";
import { AllFeatures } from "../components/AllFeatures";

export const Feature = () => {
  return (
    <div className="d-flex justify-content-center ">
      <AllFeatures />
    </div>
  );
};
