import React, { useEffect } from "react";
import welfare from "../assests/img/services/welfare.png";
import sports from "../assests/img/services/sports.png";
import confetti from "../assests/img/services/confetti.png";
import WOW from "wowjs";

const logos = [welfare, sports, confetti];

const data = [
  {
    title: "State-of-the-art Facilities",
    description:
      "Experience modernity and excellence with our top-notch facilities, including a certified GYM Center, Swimming Pool, Indoor Cricket Court, and Badminton Court.",
  },
  {
    title: "Diverse Sporting Options",
    description:
      "Explore a wide range of sports and activities catering to all ages and skill levels, from traditional favorites like cricket and badminton to recreational options like swimming and billiards.",
  },
  {
    title: "Leisure and Entertainment Offerings",
    description:
      "Indulge in relaxation and enjoyment beyond sports, with amenities such as a restaurant, cafe, gem store, and billiards/snooker lounge, providing the perfect atmosphere for socializing and unwinding.",
  },
];

export const Feature = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <div>
      <div className="container-fluid my-5">
        <div className="row">
          {data.map((item, index) => (
            <div className="col-lg-4 p-0">
              <div
                className={`d-flex align-items-center text-white px-5 wow ${index == 0 ? "slideInLeft" : index == 1 ? "fadeInUp" : index == 2 ? "slideInRight": ""} ${
                  index % 2 === 0 ? "bg-secondary" : "bg-primary"
                }`}
                style={{ minHeight: "300px" }}
              >
                {/* <i className="flaticon-training display-3 text-light mr-3"></i> */}
                <img
                  src={logos[index]}
                  alt="icon"
                  className="text-primary wow pulse infinite mr-4"
                  style={{ width: "75px", marginRight: "4px" }}
                />
                <div className="">
                  <h2 className="text-white mb-3">{item.title}</h2>
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
