import React, { useEffect, useState } from 'react';
import {  Modal } from 'antd';
import { Input } from 'antd';
const ModalEdit = ({
    isModalOpen,
    setIsModalOpen,
    attitude,
    datas,
    handleOk,
    setNewValue,
    newValue
}) => {
    
 
  return (
    <>
     <Modal
        title="You can change your value here"
        centered
        open={isModalOpen}
        onOk={() => handleOk()}
        onCancel={() => setIsModalOpen(false)}
        
      >
        <p>Enter New {attitude  }</p>
        <Input 
        placeholder="Enter New Value"
        value={newValue} 
        onChange={(e) => setNewValue(e.target.value)}
        />
        
      </Modal>
    </>
  );
};
export default ModalEdit;