import React from 'react';
import img1 from "../assests/img/about-1.jpg";
import img2 from "../assests/img/about-2.jpg";
import img3 from "../assests/img/about-3.jpg";
import img4 from "../assests/img/about-4.jpg";

export const RestaurantAbout = () => {
  return (
    <div><div className="container-xxl py-5">
    <div className="container">
        <div className="row g-5 align-items-center">
            <div className="col-lg-6">
                <div className="row g-3">
                    <div className="col-6 text-start">
                        <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.1s" src={img1}/>
                    </div>
                    <div className="col-6 text-start">
                        <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.3s" src={img2} style={{marginTop:"25%"}}/>
                    </div>
                    <div className="col-6 text-end">
                        <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.5s" src={img3}/>
                    </div>
                    <div className="col-6 text-end">
                        <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.7s" src={img4}/>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <h5 className="section-title ff-secondary text-start text-primary fw-normal wow fadeInUp">About Us</h5>
                <h1 className="mb-4 wow fadeInUp">Welcome to <i className="fa fa-utensils text-primary me-2"></i><span className=' font-weight-bold wow flash'>Club <span className='text-primary'>73</span></span></h1>
                <p className="mb-4 wow fadeInUp">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos erat ipsum et lorem et sit, sed stet lorem sit.</p>
                <p className="mb-4 wow fadeInUp">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet</p>
                <div className="row g-4 mb-4">
                    <div className="col-sm-6">
                        <div className="d-flex align-items-center border-start border-5 border-primary px-3 wow fadeInUp">
                            <h1 className="flex-shrink-0 display-5 text-primary mb-0 wow flash" data-toggle="counter-up">15</h1>
                            <div className="ps-4">
                                <p className="mb-0 wow fadeInUp">Years of</p>
                                <h6 className="text-uppercase mb-0 wow fadeInUp">Experience</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="d-flex align-items-center border-start border-5 border-primary px-3 wow fadeInUp">
                            <h1 className="flex-shrink-0 display-5 text-primary mb-0 wow flash" data-toggle="counter-up">50</h1>
                            <div className="ps-4">
                                <p className="mb-0 wow fadeInUp">Popular</p>
                                <h6 className="text-uppercase mb-0 wow fadeInUp">Master Chefs</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <a className="btn btn-primary py-3 px-5 mt-2" href="">Read More</a> */}
            </div>
        </div>
    </div>
</div></div>
  )
}
