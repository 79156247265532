import React, { useEffect } from "react";

import Feature1 from "../assests/img/feature-1.jpg";
import Feature2 from "../assests/img/feature-2.jpg";
import Feature3 from "../assests/img/feature-3.jpg";
import Feature4 from "../assests/img/feature-4.jpg";
import WOW from "wowjs";

export const WhyChoose = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <div>
      <div className="container feature pt-5">
        <div className="d-flex flex-column text-center mb-5">
          <h4 className="text-primary font-weight-bold wow flash">
            Why Choose Us?
          </h4>
          <h4 className="display-4 font-weight-bold wow flash">
            Benifits of Joining Our GYM
          </h4>
        </div>
        <div className="row">
          <div className="col-md-6 mb-5">
            <div className="row align-items-center">
              <div className="col-sm-5">
                <img
                  className="img-fluid mb-3 mb-sm-0 wow fadeInLeft"
                  src={Feature1}
                  alt="Image"
                />
                <div>
                  <i className="flaticon-barbell wow flash"></i>
                </div>
              </div>
              <div className="col-sm-7 wow fadeInLeft">
                <h4 className="font-weight-bold">Expert Coaching</h4>
                <p>
                  Our gym offers personalized training sessions with certified
                  and experienced coaches to help you achieve your fitness goals
                  effectively and safely.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-5">
            <div className="row align-items-center">
              <div className="col-sm-5">
                <img
                  className="img-fluid mb-3 mb-sm-0 wow fadeInRight"
                  src={Feature2}
                  alt="Image"
                />
                <i className="flaticon-training wow flash"></i>
              </div>
              <div className="col-sm-7  wow fadeInRight">
                <h4 className="font-weight-bold ">Customized Meal Plans</h4>
                <p>
                  Receive tailored meal plans designed by our nutrition experts
                  to complement your workout routine and support your health and
                  fitness journey.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-5">
            <div className="row align-items-center">
              <div className="col-sm-5">
                <img
                  className="img-fluid mb-3 mb-sm-0 wow fadeInLeft"
                  src={Feature3}
                  alt="Image"
                />
                <i className="flaticon-trends wow flash"></i>
              </div>
              <div className="col-sm-7 wow fadeInLeft">
                <h4 className="font-weight-bold">Invigorating Atmosphere</h4>
                <p>
                  Work out in a motivating and energetic environment that
                  inspires you to push your limits and achieve your best
                  performance.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-5">
            <div className="row align-items-center">
              <div className="col-sm-5">
                <img
                  className="img-fluid mb-3 mb-sm-0 wow fadeInRight"
                  src={Feature4}
                  alt="Image"
                />
                <i className="flaticon-support wow flash"></i>
              </div>
              <div className="col-sm-7  wow fadeInRight">
                <h4 className="font-weight-bold">State-of-the-Art Equipment</h4>
                <p>
                  Our facility is equipped with the latest fitness technology
                  and a wide range of high-quality equipment to accommodate all
                  your workout needs, ensuring you have the best tools for your
                  fitness regimen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
