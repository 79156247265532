import React, { useEffect, useState } from "react";
import { Button, Image, Input, Modal, Select } from "antd";
import { Tabs } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import generatePDF from "react-to-pdf";
import { Bill } from "../../utils/Bill";
import { Radio } from "antd";
import ReactToPrint from "react-to-print";
import { ApiClient } from "../../utils/ApiClient";
import { BarcodeIdGenerate } from "../../utils/BarcodeIdGenerate";
import toast from "react-hot-toast";

const FoodMenu = () => {
  const [payment, setPayment] = useState(0);
  const addItem = async () => {
    await ApiClient.post("/item/", newItem);
    setOpenAddModal(false);
    toast.success("Item Added Successfully");
    fetchData();
    setNewItem({});
  };

  const [serviceCharge, setServiceCharge] = React.useState(0);
  const targetRef = React.useRef(null);
  const [newItem, setNewItem] = useState({});
  const [openAddModal, setOpenAddModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = React.useState(Number(0));

  const [tabs, setTabs] = useState([]);

  const user = useSelector((state) => state.auth.name);

  const [discount, setDiscount] = useState(0);
  const [isDiscount, setIsDiscount] = useState(false);

  const handleDiscount = () => {
    setIsDiscount(true);
  };

  const handlePrint = async () => {
    await generatePDF(targetRef, { filename: `${barcode}.pdf` });
  };

  useEffect(() => {
    fetchDataCatogory();
  }, []);
  const [subTotal, setSubTotal] = useState(0);

  useEffect(() => {
    //console.log(total, discount, serviceCharge);
    const calculatedSubTotal = Number(total) - discount + Number(serviceCharge);
    setSubTotal(calculatedSubTotal.toFixed(0));
  }, [discount, total, serviceCharge]);

  const fetchDataCatogory = async () => {
    try {
      const response = await ApiClient.get("/category");
      const tab = await response.data.map((item) => ({
        title: item.name,
        content: `Content of Tab Pane ${item.order}`,
        key: item.name,
      }));
      setTabs(tab);
      //console.log(tab);
    } catch (error) {
      //console.log(error);
      toast.error("Error in Fetching Data");
    }
  };

  const [radioValue, setRadioValue] = React.useState(1);

  useEffect(() => {
    fetchData();
  }, []);

  const [getData, setGetData] = React.useState([]);
  const name = useSelector((state) => state.auth.name);
  const [discountValue, setDiscountValue] = React.useState(0);
  useEffect(() => {
    setServiceCharge((total * 0.1).toFixed(0));
  }, [total]);

  const fetchData = async () => {
    try {
      const response = await ApiClient.get("/item");
      setGetData(response.data);
    } catch (error) {
      //console.log(error);
      toast.error("Error in Fetching Data");
    }
  };
  const [tempItem, setTempItem] = React.useState([]);

  useEffect(() => {
    let total = 0;
    tempItem.forEach((item) => {
      total += item.price * item.count;
    });
    setTotal(total.toFixed(0));
  }, [tempItem]);
const [barcode, setBarcode] = React.useState("");
  const handleOrder = async () => {
    setBarcode(BarcodeIdGenerate())
    await generatePDF(targetRef, { filename: "page1.pdf" });
    try {
      const response = await ApiClient.post("/order", {
        foodQuantity: tempItem,
        totalPrice: subTotal,
        total: (total- discountValue),
        orderedBy: "Customer",
        discount: discountValue,
        serviceCharge: serviceCharge,
        cashiersName: name,
        paidAmount: payment,
        barcode: barcode,
      });
      //console.log(response.data);
      setOpen(true);
      toast.success("Order Placed Successfully");
      // setTempItem([]);
    } catch (error) {
      //console.log(error);
      toast.error("Error in Placing Order");
    }
  };

  const handleDelete = (id) => {
    const updatedTempItem = tempItem.filter((item) => item._id !== id);
    setTempItem(updatedTempItem);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-7">
          <div className="d-flex justify-content-center">
            <Button
              type="primary"
              className="col-md-6 "
              onClick={() => setOpenAddModal(true)}
            >
              <h5 className="text-white">Add New Item</h5>
            </Button>
          </div>
          <Tabs
            defaultActiveKey="1"
            tabPosition="top"
            style={{
              height: 220,
            }}
            items={tabs.map((tab, i) => {
              return {
                label: tab.title,
                key: tab.key,
                children: (
                  <div key={i}>
                    {getData.map(
                      (item) =>
                        item.category === tab.key && (
                          <div className="row mb-2" key={item.id}>
                            <div className="col-md-7">
                              <h3>{item.name}</h3>
                            </div>
                            <div className="col-md-3 d-flex gap-3">
                              <h4>Rs.{item.price}/=</h4>
                            </div>
                            <div className="col-md-2 /">
                              <div className="">
                                <PlusCircleOutlined
                                  style={{ fontSize: 32 }}
                                  onClick={() => {
                                    const existingItemIndex =
                                      tempItem.findIndex(
                                        (temp) => temp._id === item._id
                                      );

                                    if (existingItemIndex !== -1) {
                                      // If the item already exists, increase its count by one
                                      const updatedTempItem = [...tempItem];
                                      updatedTempItem[
                                        existingItemIndex
                                      ].count += 1;
                                      setTempItem(updatedTempItem);
                                    } else {
                                      // If the item does not exist, add it with count 1
                                      setTempItem([
                                        ...tempItem,
                                        { ...item, count: 1 },
                                      ]);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                ),
              };
            })}
          />
        </div>
        <Modal
          title="Bill"
          open={open}
          // onOk={handlePrint}
          footer={[
            <Button key="back" onClick={()=>setOpen(false)}>
              Cancel
            </Button>,
            // <ReactToPrint
            //   onBeforePrint={handlePaymentSubmit}
            //   trigger={() => (
            //     <Button key="submit" type="primary">
            //       Ok
            //     </Button>
            //   )}
            //   content={() => targetRef.current}
            // />,
          ]}
        >
          <Bill
            targetRef={targetRef}
            tempItem={tempItem}
            total={total}
            subTotal={subTotal}
            discount={discount}
            serviceCharge={serviceCharge}
            customer={"Customer"}
            barcode={barcode}
            discountValue={discountValue}
            setDiscountValue={setDiscountValue}
          />
        </Modal>

        <div className="col-md-5">
          <div className="card">
            <div className="card-header px-3">
              <h2>WishList</h2>
            </div>
            <ul className="list-group list-group-light list-group-small">
              {tempItem.map((item) => (
                <li className="list-group-item px-3">
                  <div className="d-flex align-items-center  row">
                    <div className="col-md-6">
                      <h4 className="m-0 ">{item.name}</h4>
                      <span className="text-muted">Rs. {item.price}/=</span>
                    </div>
                    <div className="col-md-4">
                      <h6>{item.count} Piece</h6>
                    </div>
                    <div className="col-md-2">
                      <DeleteOutlined
                        style={{ fontSize: 32 }}
                        onClick={() => handleDelete(item._id)}
                      />
                    </div>
                  </div>
                </li>
              ))}
              <li className="list-group-item px-3">
                <div className=" row">
                  <div className="col-md-7">
                    <h5 className="m-0 ">Total</h5>
                  </div>

                  <div className="col-md-5">
                    <h5 className="text-muted mt-1 d-flex justify-content-end">
                      Rs. {total}/=
                    </h5>
                  </div>
                </div>{" "}
                <div className=" row">
                  <div className="col-md-7">
                    <h5 className="m-0 ">Service Charge</h5>
                  </div>

                  <div className="col-md-5">
                    <h5 className="text-muted mt-1 d-flex justify-content-end">
                      Rs. {serviceCharge}/=
                    </h5>
                  </div>
                </div>
                {discount > 0 && (
                  <div className=" row">
                    <div className="col-md-7">
                      <h5 className="m-0 ">Discount</h5>
                    </div>

                    <div className="col-md-5">
                      <h5 className="text-muted mt-1 d-flex justify-content-end">
                        Rs. {Number(discount)}/=
                      </h5>
                    </div>
                  </div>
                )}
                <div className=" row justify-content-between">
                  <div className="col-md-7">
                    <h4 className="m-0 ">SubTotal</h4>
                  </div>

                  <div className="col-md-5 ">
                    <h4 className="text-white text-bg-primary mt-1 d-flex justify-content-end p-1 rounded">
                      Rs. {subTotal}/=
                    </h4>
                  </div>
                </div>
                <div className=" row ">
                  <div className="col-md-7 ">
                    <h4 className="m-0 ">Payment (Rs.)</h4>
                  </div>

                  <div className="col-md-5 d-flex justify-content-end">
                    <Input
                      placeholder="Enter Amount"
                      className=" h5"
                      type="number"
                      style={{ textAlign: "end" }}
                      value={payment}
                      onChange={(e) => setPayment(e.target.value)}
                    />
                  </div>
                </div>
                <div className=" row  justify-content-between">
                  <div className="col-md-7">
                    <h4 className="m-0 ">Balance</h4>
                  </div>

                  <div className="col-md-5 ">
                    <h3 className=" text-bg-success mt-1 text-white d-flex justify-content-end p-1 rounded">
                    Rs. {(payment - subTotal).toFixed(0)}/=

                    </h3>
                  </div>
                </div>
              </li>
              <li className="list-group-item px-3">
                <div className="d-flex justify-content-between  align-items-center mx-1 row">
                  <Button
                    type="primary"
                    className="col-md-3 warning"
                    onClick={() => {
                      setTempItem([]);
                    }}
                  >
                    <h5 className="text-white">Clear Bucket</h5>
                  </Button>
                  <Button
                    type="primary"
                    className="col-md-3 "
                    onClick={handleDiscount}
                  >
                    <h5 className="text-white">Add Discount</h5>
                  </Button>
                  <Button
                    type="primary"
                    className="col-md-3 "
                    onClick={handleOrder}
                  >
                    <h5 className="text-white">Order Now</h5>
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <Modal
          title="Add a New Item"
          open={openAddModal}
          onOk={addItem}
          onCancel={() => {
            setOpenAddModal(false);
          }}
        >
          <div>
            <Input
              placeholder="Item Name"
              onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
              value={newItem.name}
            />
            <Input
              placeholder="Item Description"
              onChange={(e) =>
                setNewItem({ ...newItem, description: e.target.value })
              }
              value={newItem.description}
            />
            <Input
              //number
              type="number"
              value={newItem.price}
              placeholder="Item Price ex:-200"
              onChange={(e) =>
                setNewItem({ ...newItem, price: e.target.value })
              }
            />
            <Select
              value={newItem.category}
              placeholder="Select Category"
              onChange={(value) => setNewItem({ ...newItem, category: value })}
            >
              {tabs.map((tab) => (
                <Select.Option key={tab.key} value={tab.key}>
                  {tab.title}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Modal>
        <Modal
          title="Add a Discount"
          open={isDiscount}
          onOk={() => {
            setIsDiscount(false);
          }}
          onCancel={() => {
            setIsDiscount(false);
            setDiscount(0);
          }}
        >
          <div>
            <Radio.Group
              onChange={(e) => setRadioValue(e.target.value)}
              value={radioValue}
            >
              <Radio value={1}>As a Price</Radio>
              <br />
              <div>
                <Input
                  //number
                  type="number"
                  value={discount}
                  placeholder="Discount Amount"
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </div>
              <Radio value={2}>As a Percentage</Radio>
              <Input
                //number
                type="number"
                max={100}
                // value={discount.p}
                placeholder="Discount Percentage"
                onChange={(e) =>
                  setDiscount(Number(total) * (e.target.value / 100))
                }
              />
            </Radio.Group>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default FoodMenu;
