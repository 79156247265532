import React from 'react'
import { Carousel } from '../components/Carousel'
import { MainServices } from '../components/MainServices'
import { Subcribe } from '../components/Subcribe'
import { AboutData } from '../components/AboutData'
import { Feature } from '../components/Feature'
import { WhyChoose } from '../components/WhyChoose'
import { Timetable } from '../components/Timetable'
import { BMI } from '../components/BMI'
import { Trainer } from '../components/Trainer'
import { Blog } from '../components/Blog'

export const Home = () => {

  return (
    <div>
        <Carousel />   
        <MainServices/> 
        <AboutData />
        <Feature />
        {/* <Blog   /> */}



        {/* <WhyChoose /> */}
        {/* <Subcribe /> */}
        {/* <Timetable /> */}
        {/* <BMI /> */}
        {/* <Trainer /> */}
    </div>
  )
}
