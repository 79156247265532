import React, { useEffect, useRef } from "react";
import img1 from "../assests/img/carousel-1.jpg";
import img2 from "../assests/img/carousel-2.jpg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUser } from "../store/slices/authSlice";
import WOW from "wowjs";

export const Carousel = () => {
  const user = useSelector(getUser);

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);



  return (
    <div style={{ zIndex: "1"}}>
      <div className="container-fluid p-0">
        <div
          id="blog-carousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="w-100" src={img2} alt="Image" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center" >
                <h2 className="display-2 text-white text-capitalize font-weight-bold m-0 wow fadeInRightBig">
                  bOOST GYM & FITNESS CENTER
                </h2> 
                <h2 className=" m-0 mt-2 mt-md-4 text-white  text-capitalize wow fadeInLeftBig">
                  Best Sports Complex In Town
                </h2>
                {user?.isLoggedIn ? null : (
                  <Link
                    to="/register"
                    className="btn btn-lg btn-outline-dark mt-3 mt-md-5 py-md-3 px-md-5 wow fadeInUp text-white font-weight-bold border-white"
                  >
                    Join Us Now
                  </Link>
                )}
              </div>
            </div>
            {/* <div className="carousel-item">
              <img className="w-100" src={img2} alt="Image" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <h3 className="text-primary text-capitalize m-0">
                  Gym & Fitness Center
                </h3>
                <h2 className="display-2 m-0 mt-2 mt-md-4 text-white font-weight-bold text-capitalize">
                  Get Body In Shape
                </h2>
                {user?.isLoggedIn ? null : (
                  <Link
                    to="/register"
                    className="btn btn-lg btn-outline-light mt-3 mt-md-5 py-md-3 px-md-5"
                  >
                    Join Us Now
                  </Link>
                )}
              </div>
            </div> */}
          </div>
          {/* <a
            className="carousel-control-prev"
            href="#blog-carousel"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon"></span>
          </a>
          <a
            className="carousel-control-next"
            href="#blog-carousel"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon"></span>
          </a> */}
        </div>
      </div>
    </div>
  );
};
