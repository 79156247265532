import React, { useEffect } from "react";
import "./FoodItem.css";
import img from "../assests/img/menu-1.jpg";
import WOW from "wowjs";

export const FoodItem = ({ data }) => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }
  , []);
  return (
    <div className="">
      <div className="d-flex align-items-center">
        <img
          className="flex-shrink-0 img-fluid rounded wow flash"
          src={img}
          alt=""
          style={{width: "80px"}}
        />
        <div className="w-100 d-flex flex-column text-start ps-4">
          <h5 className="d-flex justify-content-between border-bottom pb-2">
            <p className="text-white">{data.name}</p>
            <span className="text-primary">Rs.{data.price}/=</span>
          </h5>
          <small className="fst-italic text-white">
            {data.description}
          </small>
        </div>
      </div>
    </div>
  );
};
