import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { ApiClient } from "../utils/ApiClient";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetails, getUser } from "../store/slices/userSlice";
import toast from "react-hot-toast";
import WoW from "wowjs";

const { Meta } = Card;

export const GymPackageCard = () => {
  const [basePackages, setBasePackages] = useState([]);
  const [sixMonthPackages, setSixMonthPackages] = useState([]);
  const [twelveMonthPackages, setTwelveMonthPackages] = useState([]);

  useEffect(() => {
    new WoW.WOW().init();
  }, []);

  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const fetchPackages = async () => {
    await ApiClient.get("/gym-packages")
      .then((response) => {
        //console.log(response.data);
        setBasePackages(response.data.filter((item) => item.duration == 1));
        setSixMonthPackages(response.data.filter((item) => item.duration == 6));
        setTwelveMonthPackages(
          response.data.filter((item) => item.duration == 12)
        );
      })
      .catch((error) => {
        //console.log(error);
        toast.error("Failed to fetch packages");
      });
  };

  const createSubscription = async ({ gymPackageId, userId }) => {
    await ApiClient.post("/gymSubscription", { gymPackageId, userId })
      .then((response) => {
        toast.success("Package Registered Successfully");
        dispatch(fetchUserDetails());
      })
      .catch((error) => {
        // console.log(error.response.message)
        toast.error("Failed to register package");
      });
  };

  const handleRegister = async (gymPackageId) => {
    if (user?._id) {
      await createSubscription({ gymPackageId, userId: user._id });
    } else {
      toast.error("Please login to register for a package");
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  return (
    <div className="row w-100 justify-content-center">
      <h3 className="row text-primary my-4 justify-content-center wow zoomIn">
        Standard Packages
      </h3>
      {basePackages &&
        basePackages.map((item, index) => {
          return (
            <div key={index} className="wow slideInUp d-flex col-md-3 m-2">
              <Card
                style={{
                  width: "100%",
                  backgroundColor: "#f0f0f0",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                }}
              >
                <Meta
                  title={
                    <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                      {item.displayName}
                    </span>
                  }
                  description={`This package is for ${item.pax} ${
                    item.pax == 1 ? "member" : "members"
                  }. Subscription will be activated once you do the payment and it will stay active for ${
                    item.duration
                  } ${item.duration == 1 ? "month" : "months"}.${
                    item.isThreadMill ? " Threadmill is included." : ""
                  }`}
                />
                {/* <p>{item.pax} members</p>
                <p>{item.duration} months</p> */}
                <div className=" w-100 d-flex justify-content-end mt-4">
                  <h5 className="text-white bg-primary p-2 rounded">Rs. {item.rate} /=</h5>
                </div>
              </Card>
            </div>
          );
        })}
      <h3 className="row text-primary my-4 justify-content-center wow zoomIn">
        6 Month Packages
      </h3>
      {/* <p className="row justify-content-center">1 Month Discount!</p> */}
      {sixMonthPackages &&
        sixMonthPackages.map((item, index) => {
          return (
            <div key={index} className="d-flex col-md-3 m-2 wow slideInUp">
              <Card
                style={{
                  width: "100%",
                  backgroundColor: "#f0f0f0",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                }}
              >
                <Meta
                  title={
                    <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                      {item.displayName}
                    </span>
                  }
                  description={`This package is for ${item.pax} ${
                    item.pax == 1 ? "member" : "members"
                  }. Subscription will be activated once you do the payment and it will stay active for ${
                    item.duration
                  } ${item.duration == 1 ? "month" : "months"}.${
                    item.isThreadMill ? " Threadmill is included." : ""
                  }`}
                />
                {/* <p>{item.pax} members</p>
                <p>{item.duration} months</p> */}
                <div className="w-100 d-flex justify-content-end mt-4">
                  <div >
                    <h5 className="text-white bg-primary p-2 rounded">Rs. {item.rate} /=</h5>
                    <h6 className="text-success">Rs. {(item.rate / 5) * 1} /= Discount!</h6>
                  </div>
                </div>
              </Card>
            </div>
          );
        })}
      <h3 className="row text-primary my-4 justify-content-center wow zoomIn">
        12 Month Packages
      </h3>
      {/* <p className="row justify-content-center">3 Month Discount!</p> */}
      {twelveMonthPackages &&
        twelveMonthPackages.map((item, index) => {
          return (
            <div key={index} className="d-flex col-md-3 m-2 wow slideInUp">
              <Card
                style={{
                  width: "100%",
                  backgroundColor: "#f0f0f0",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                }}
              >
                <Meta
                  title={
                    <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                      {item.displayName}
                    </span>
                  }
                  description={`This package is for ${item.pax} ${
                    item.pax == 1 ? "member" : "members"
                  }. Subscription will be activated once you do the payment and it will stay active for ${
                    item.duration
                  } ${item.duration == 1 ? "month" : "months"}.${
                    item.isThreadMill ? " Threadmill is included." : ""
                  }`}
                />
                {/* <p>{item.pax} members</p>
                <p>{item.duration} months</p> */}
                <div className="w-100 d-flex justify-content-end mt-4">
                  <div >
                    <h5 className="text-white bg-primary p-2 rounded">Rs. {item.rate} /=</h5>
                    <h6 className="text-success">Rs. {(item.rate / 9) * 3} /= Discount!</h6>
                  </div>
                </div>
              </Card>
            </div>
          );
        })}
    </div>
  );
};
