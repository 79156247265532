import { Button, Modal, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { ApiClient } from "../../utils/ApiClient";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Form, Input, Space } from "antd";
import { TfiReload } from "react-icons/tfi";
import ReactToPrint from "react-to-print";
import generatePDF from "react-to-pdf";

import {
  DeleteColumnOutlined,
  DeleteFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import { BarcodeIdGenerate } from "../../utils/BarcodeIdGenerate";
import { Bill } from "../../utils/Bill";
import toast from "react-hot-toast";
import StatusTag from "./StatusTag";
import { PRICES } from "../../utils/Prices";

export const GymSubscriptions = () => {
  const targetRef = React.useRef(null);
  const [openActivationModal, setOpenActivationModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const [isloading, setIsLoading] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState();
  const [selectedSubscriptionDetails, setSelectedSubscriptionDetails] =
    useState();
  const [userID, setUserID] = useState();
  const [openCreateNewModal, setOpenCreateNewModal] = useState(false);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [gymPackages, setGymPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState();
  const [users, setUsers] = useState();
  const [isSubmitButtonDisable, setIsSubmitButtonDisable] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [discountValue, setDiscountValue] = React.useState(0);
  const [customerId, setCustomerId] = useState("");

  const user = useSelector((state) => state.user.user);
  //console.log(user);

  const [barcode, setBarcode] = useState("");

  const handleBypass = async (record) => {
    // console.log(record);
    try {
      const response = await ApiClient.put(
        `/gymSubscription/bypass/${record._id}`,
        {
          cashierId: user._id,
        }
      );

      fetchSubscriptions();
      toast.success("Subscription Bypassed");
    } catch (error) {
      // console.error(error);
      toast.error("Failed to bypass subscription");
    }
  };

  const columns = [
    // {
    //   title: "Subscription ID",
    //   dataIndex: "_id",
    //   key: "_id",
    // },
    // {
    //   title: "User Name",
    //   dataIndex: "userName",
    //   key: "userName",
    // },
    {
      title: "Participants",
      dataIndex: "participants",
      key: "participants",
      render: (text, record, index) => {
        return (
          <div className="d-flex flex-column">
            {record.participants.map((item, index) => {
              return <div key={index}>{`${item.name} (${item.email})`}</div>;
            })}
          </div>
        );
      },
    },
    {
      title: "Package Name",
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => <span>{`Rs.${record.amount}/=`}</span>,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Payment",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text) => <StatusTag status={text} />,
    },
    {
      title: "Status",
      dataIndex: "subscriptionStatus",
      key: "subscriptionStatus",
      render: (text) => <StatusTag status={text} />,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, index) => {
        return (
          <div className="d-flex gap-2">
            {record.paymentStatus == "Pending" &&
            record.subscriptionStatus == "Pending" ? (
              <div>
                <Button
                  onClick={() => {
                    handleActivate(record);
                  }}
                >
                  Activate
                </Button>
                <Button
                  onClick={() => {
                    handleBypass(record);
                  }}
                >
                  Bypass
                </Button>
              </div>
            ) : record.subscriptionStatus != "Closed" &&
              record.paymentStatus == "Pending" ? (
              <Button onClick={() => handlePayment(record)}>Payment</Button>
            ) : null}
            {record.paymentStatus == "Pending" &&
              (record.subscriptionStatus == "Pending" ||
                record.subscriptionStatus == "Inactive") && (
                <Button
                  onClick={() => handleDeleteModalOpen(record)}
                  className="d-flex justify-content-center align-items-center"
                >
                  <DeleteOutlined />
                </Button>
              )}
          </div>
        );
      },
    },
  ];

  const fetchSubscriptions = async () => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get("/gymSubscription");
      setData(
        response.data?.map((item) => ({
          ...item,
          userName: item?.user?.name,
          packageName: item?.gymPackage?.displayName,
          startDate: dayjs(item.startDate).format("YYYY-MMM-DD"),
          endDate: dayjs(item.endDate).format("YYYY-MMM-DD"),
        }))
      );
      setIsLoading(false);
      //   console.log(response.data);
    } catch (error) {
      // console.error(error);
      toast.error("Failed to fetch subscriptions");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptions();
    fetchPackages();
    fetchUsers();
  }, []);

  useEffect(() => {
    form.setFieldsValue({ createdBy: user?._id });
  }, [user]);

  useEffect(() => {
    //console.log(selectedPackage);
    setIsSubmitButtonDisable(
      selectedPackage?.pax == form.getFieldValue("participants")?.length
        ? false
        : true
    );
  }, [selectedPackage]);

  const [customer, setCustomer] = useState("");

  const handleActivate = (record) => {
    setCustomer(record.participants[0].name);
    setCustomerId(record.participants[0]._id);
    setSelectedSubscription(record._id);
    setOpenActivationModal(true);
    setUserID(record.user._id);
    setBarcode(BarcodeIdGenerate());
    setSelectedSubscriptionDetails(record.gymPackage);
  };

  const handlePayment = (record) => {
    setCustomer(record.participants[0].name);
    setCustomerId(record.participants[0]._id);
    setSelectedSubscription(record._id);
    setOpenPaymentModal(true);
    setUserID(record.user._id);
    setSelectedSubscriptionDetails(record.gymPackage);
    setBarcode(BarcodeIdGenerate());
  };

  const handleActivateClose = () => {
    setSelectedSubscription(null);
    setOpenActivationModal(false);
    setSelectedSubscriptionDetails(null);
  };

  const handleActivateSubmit = async () => {
    await activateSubscription();
    setOpenActivationModal(false);
    setSelectedSubscriptionDetails(null);
  };

  const handlePaymentClose = () => {
    //console.log("Payment Close");
    setSelectedSubscription(null);
    setOpenPaymentModal(false);
    setOpenActivationModal(false);
  };
  const handlePrint = async () => {
    await generatePDF(targetRef, { filename: `${barcode}.pdf` });
  };
  const handlePaymentSubmit = async () => {
    handlePrint();
    //console.log("Payment Submit");
    await paymentSubscription();
    setOpenPaymentModal(false);
    setSelectedSubscriptionDetails(null);
  };

  const activateSubscription = async () => {
    try {
      const response = await ApiClient.put(
        `/gymSubscription/activate/${selectedSubscription}`,
        {
          cashierId: user._id,
          userId: customerId,
          amount: PRICES.GYM_ACTIVATION_FEE,
          paymentCategory: "GymSubscriptionActivation",
          barcode: barcode,
          discount: discountValue,
        }
      );
      fetchSubscriptions();
      toast.success("Subscription Activated");
      setOpenCreateNewModal(false);
    } catch (error) {
      // console.error(error);
      toast.error("Failed to activate subscription");
    }
  };

  const paymentSubscription = async () => {
    try {
      const response = await ApiClient.put(
        `/gymSubscription/payment/${selectedSubscription}`,
        {
          cashierId: user._id,
          userId: customerId,
          amount: selectedSubscriptionDetails.rate - discountValue,
          paymentCategory: "GymSubscription",
          barcode: barcode,
          discount: discountValue,
        }
      );
      fetchSubscriptions();
      toast.success("Payment Successful");
      //console.log(response.data);
    } catch (error) {
      // console.error(error);
      toast.error("Failed to make payment");
    }
  };

  const handleCreateNew = () => {
    setOpenCreateNewModal(true);
  };



  const handleCreateNewClose = () => {
    setOpenCreateNewModal(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setOpenCreateNewModal(false);
    form.resetFields();
  };

  const fetchPackages = async () => {
    try {
      const response = await ApiClient.get("/gym-packages");
      setGymPackages(response.data);
    } catch (error) {
      //console.log(error);
      toast.error("Failed to fetch gym packages");
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await ApiClient.get("/user/getUsers");
      setUsers(response.data);
    } catch (error) {
      //console.log(error);
      toast.error("Failed to fetch users");
    }
  };

  const preparePackageOptions = () => {
    return gymPackages.map((item) => {
      return (
        <Option key={item._id} value={item._id}>
          {item.displayName}
        </Option>
      );
    });
  };

  const prepareUserOptions = () => {
    return users
      ?.filter(
        (item) =>
          item.gymSubscription == null || item.gymSubscription == undefined
      )
      .map((item) => {
        return (
          <Option key={item._id} value={item._id}>
            {item.email}
          </Option>
        );
      });
  };

  const onFinish = (values) => {
    //console.log("Success:", values);
    createSubscription(values);
    setOpenCreateNewModal(false);
    form.resetFields();
    setSelectedPackage(null);
    // toast.success("Subscription Created Successfully");
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
    toast.error("Failed to create subscription");
  };

  const createSubscription = async (values) => {
    try {
      const response = await ApiClient.post("/gymSubscription", {
        ...values,
        userId: user._id,
      });
      fetchSubscriptions();
      fetchUsers();
      form.resetFields();
      //console.log(response.data);
      toast.success("Subscription Created Successfully");
    } catch (error) {
      // console.error(error);
      toast.error("Failed to create subscription");
    }
  };
  const [selectedPackageRate, setSelectedPackageRate] = useState(0);

  const handleDeleteModalOpen = (record) => {
    setSelectedPackageRate(record.rate);
    setSelectedSubscription(record._id);
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setSelectedSubscription(null);
    setDeleteModalOpen(false);
  };

  const handleDeleteSubmit = async () => {
    try {
      const response = await ApiClient.delete(
        `/gymSubscription/${selectedSubscription}`
      );
      fetchSubscriptions();
      fetchUsers();
      toast.success("Subscription Deleted Successfully");
      //console.log(response.data);
    } catch (error) {
      // console.error(error);
      toast.error("Failed to delete subscription");
    }
    setDeleteModalOpen(false);
  };

  return (
    <div>
      <div className="d-flex justify-content-center gap-2">
        <Button
          type="primary"
          // className="my-2"
          onClick={() => {
            handleCreateNew();
          }}
        >
          + New Subscription
        </Button>
        <Button type="primary" onClick={() => fetchSubscriptions()}>
          <TfiReload />
        </Button>
      </div>
      <Table columns={columns} dataSource={data} loading={isloading} scroll={{x:1300}}/>
      <Modal
        title="Activate Subscription"
        open={openActivationModal}
        footer={[
          <Button key="back" onClick={handleActivateClose}>
            Cancel
          </Button>,
          <ReactToPrint
            onBeforePrint={handleActivateSubmit}
            trigger={() => (
              <Button key="submit" type="primary">
                Ok
              </Button>
            )}
            content={() => targetRef.current}
          />,
        ]}
      >
        <Bill
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
          targetRef={targetRef}
          tempItem={[
            {
              name: "Gym Registration Fee",
              count: 1,
              price: PRICES.GYM_ACTIVATION_FEE,
            },
          ]}
          total={PRICES.GYM_ACTIVATION_FEE}
          subTotal={PRICES.GYM_ACTIVATION_FEE * 1}
          balance={PRICES.GYM_ACTIVATION_FEE}
          barcode={barcode}
          customer={customer.split(" ")[0]}
          discount={0}
        />
      </Modal>

      <Modal
        title="Payment Subscription"
        open={openPaymentModal}
        footer={[
          <Button key="back" onClick={handlePaymentClose}>
            Cancel
          </Button>,
          <ReactToPrint
            onBeforePrint={handlePaymentSubmit}
            trigger={() => (
              <Button key="submit" type="primary">
                Ok
              </Button>
            )}
            content={() => targetRef.current}
          />,
        ]}
      >
        <Bill
          targetRef={targetRef}
          tempItem={[
            {
              name:
                selectedSubscriptionDetails?.displayName + " Gym Subcription",
              count: 1,
              price: selectedSubscriptionDetails?.rate,
            },
          ]}
          total={selectedSubscriptionDetails?.rate * 1}
          subTotal={selectedSubscriptionDetails?.rate * 1}
          balance={selectedSubscriptionDetails?.rate * 1}
          barcode={barcode}
          customer={customer.split(" ")[0]}
          discount={0}
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
        />
      </Modal>
      <Modal
        title="Create New Subscription"
        open={openCreateNewModal}
        footer={false}
        onCancel={() => {
          form.resetFields();
          setSelectedPackage(null);
        }}
      >
        <Form
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Package ID"
            name="gymPackageId"
            rules={[
              {
                required: true,
                message: "Please insert your package ID!",
              },
            ]}
          >
            <Select
              onChange={(value) => {
                setSelectedPackage(
                  gymPackages.find((item) => item._id == value)
                );
              }}
            >
              {preparePackageOptions()}
            </Select>
          </Form.Item>
          <Form.Item
            label="Participants"
            name="participants"
            rules={[
              {
                required: true,
                message: "Please select all the Participants!",
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select Participants"
              // defaultValue={["china"]}
              onChange={(value) => {
                setIsSubmitButtonDisable(
                  value.length == selectedPackage?.pax ? false : true
                );
              }}
              // disabled={selectedPackage?.pax == 1 ? true : false}
              disabled={selectedPackage == null ? true : false}
              maxCount={selectedPackage?.pax}
              // options={options}
              optionRender={(option) => <Space>{option.label}</Space>}
            >
              {prepareUserOptions()}
            </Select>
          </Form.Item>
          <Form.Item
            // wrapperCol={{
            //   offset: 8,
            //   span: 16,
            // }}
            className="d-flex justify-content-end"
          >
            <div className="d-flex gap-2">
              <Button type="primary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isSubmitButtonDisable}
              >
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Delete Subscription"
        open={deleteModalOpen}
        onCancel={handleDeleteModalClose}
        onOk={handleDeleteSubmit}
      >
        <p>Are you sure you want to delete this subscription?</p>
      </Modal>
    </div>
  );
};
