import React, { useState } from "react";
import { Button, Modal, Tag } from "antd";
import { Form, Input, InputNumber } from "antd";
import { Row, Col } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { ApiClient } from "../../utils/ApiClient";
import toast from "react-hot-toast";

const CheckInModal = ({ open, setOpen, record, fetchData }) => {
  const [checkedInList, setCheckedInList] = useState([]);
  const [input, setInput] = useState();



  const handleChange = (e) => {
    setInput(e.target.value);
    if (e.target.value?.length > 7) {
      if (checkedInList?.includes(e.target.value)) {
        setInput(null);
      } else {
        setCheckedInList((prev) => [...prev, e.target.value]);
        setInput(null);
      }
    }
  };

  const handleClose = () => {
    setCheckedInList([]);
    setInput(null);
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const response = await ApiClient.post(`/pool-event/check-in/${record._id}`, {
        qrList: checkedInList,
      });
      //console.log(response);
      fetchData();
      toast.success("Checked In Successfully");

    } catch (error) {
      //console.log(error);
    }
    setOpen(false);
  };

  const handleTagClose = (e) => {
    //console.log(e);
    setCheckedInList((prev) => prev.filter((item) => item != e));
  };

  return (
    <>
      <Modal
        title="Check-In"
        open={open}
        onOk={() => handleSubmit()}
        onCancel={() => handleClose()}
        okButtonProps={{
          // disabled: checkedInList?.length < record?.noOfAdult + record?.noOfChild,
        }}
      >
        <div>
          <h6>Created By: {record?.createdBy}</h6>
          <h6>Date : {record?.date}</h6>
          <h6>Check-In : {record?.checkIn}</h6>
          <h6>Check-Out : {record?.checkOut}</h6>
          <h6>Number of Adults : {record?.noOfAdult}</h6>
          <h6>Number of Children : {record?.noOfChild}</h6>

          <Input
            type="number"
            placeholder="Scan QR"
            onChange={(e) => handleChange(e)}
            value={input}
            disabled={
              checkedInList?.length >= record?.noOfAdult + record?.noOfChild
            }
          ></Input>

          <div>
            {checkedInList?.map((item) => {
              return (
                <Tag
                  key={item}
                  closable
                  onClose={() => handleTagClose(item)}
                  color="blue"
                >
                  {item}
                </Tag>
              );
            })}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CheckInModal;
