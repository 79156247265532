import React, { useEffect, useState } from "react";
import { Card, Modal } from "antd";
import swimming from "../assests/img/services/img3.jpg";
import cricket from "../assests/img/services/img1.jpg";
import food from "../assests/img/services/img2.jpg";
import billiard from "../assests/img/services/img4.jpg";
import badminton from "../assests/img/services/img5.jpg";
import gym from "../assests/img/services/img6.jpg";
import gem from "../assests/img/services/img7.jpg";
import WOW from "wowjs";
const images = [swimming, cricket, food, billiard, badminton, gym, gem];

const data = [
  {
    title: "Swimming Pool",
    description:
      "Dive into relaxation with our luxurious swimming pool. Whether you're seeking a refreshing swim or a tranquil escape, our pool provides the perfect oasis.",
  },
  {
    title: "Indoor Cricket Court",
    description:
      "Experience the thrill of indoor cricket in our dedicated court. Gather your team and enjoy a spirited game in a comfortable environment, rain or shine.",
  },
  {
    title: "Restaurant & Cafe",
    description:
      "Indulge your senses in our exquisite restaurant and cafe. From gourmet meals to artisanal coffee, our culinary offerings cater to every palate.",
  },
  {
    title: "Billiard Lounge",
    description:
      "Unwind and enjoy a game of billiards in our exclusive lounge. Whether you're a casual player or a seasoned pro, our billiard lounge offers the perfect ambiance for relaxation.",
  },
  {
    title: "Badminton Court",
    description:
      "Serve, smash, and score on our professional badminton court. Whether you're a beginner or a seasoned player, our court provides the perfect setting for an exhilarating match.",
  },
  {
    title: "Certified GYM Center",
    description:
      "Our Certified GYM Center offers state-of-the-art facilities and expert trainers to help you achieve your fitness goals.",
  },
  {
    title: "Gem Store",
    description:
      "Explore a world of elegance and beauty in our gem store. Discover exquisite jewels and gemstones curated to perfection.",
  },
];

const addtionalInfo = [
  {
    title: "Swimming Pool",
    description:
      "Dive into relaxation with our luxurious swimming pool. Whether you're seeking a refreshing swim or a tranquil escape, our pool provides the perfect oasis.",
    rules: [
      "No diving",
      "No running",
      "No food or drinks",
      "No glass containers",
      "No pets allowed",
    ],
    guideLines: [
      "Children under 12 must be accompanied by an adult",
      "Shower before entering the pool",
      "Use of floatation devices is allowed",
    ],
    timings: "Open from 6:00 AM to 8:00 PM",
  },
  {
    title: "Indoor Cricket Court",
    description:
      "Experience the thrill of indoor cricket in our dedicated court. Gather your team and enjoy a spirited game in a comfortable environment, rain or shine.",
    rules: [
      "No shoes allowed",
      "No food or drinks",
      "No pets allowed",
      "No smoking",
    ],
    guideLines: [
      "Children under 12 must be accompanied by an adult",
      "Use of protective gear is recommended",
      "Respect other players",
    ],
    timings: "Open from 8:00 AM to 10:00 PM",
  },
  {
    title: "Restaurant & Cafe",
    description:
      "Indulge your senses in our exquisite restaurant and cafe. From gourmet meals to artisanal coffee, our culinary offerings cater to every palate.",
    rules: ["No outside food or drinks", "No pets allowed", "No smoking"],
    guideLines: [
      "Children under 12 must be accompanied by an adult",
      "Reservations are recommended",
      "Takeout and delivery available",
    ],
    timings: "Open from 7:00 AM to 11:00 PM",
  },
  {
    title: "Billiard Lounge",
    description:
      "Unwind and enjoy a game of billiards in our exclusive lounge. Whether you're a casual player or a seasoned pro, our billiard lounge offers the perfect ambiance for relaxation.",
    rules: ["No outside food or drinks", "No smoking", "No pets allowed"],
    guideLines: [
      "Children under 12 must be accompanied by an adult",
      "Respect other players",
      "Use of chalk is recommended",
    ],
    timings: "Open from 10:00 AM to 12:00 AM",
  },
  {
    title: "Badminton Court",
    description:
      "Serve, smash, and score on our professional badminton court. Whether you're a beginner or a seasoned player, our court provides the perfect setting for an exhilarating match.",
    rules: [
      "No shoes allowed",
      "No food or drinks",
      "No pets allowed",
      "No smoking",
    ],
    guideLines: [
      "Children under 12 must be accompanied by an adult",
      "Use of protective gear is recommended",
      "Respect other players",
    ],
    timings: "Open from 8:00 AM to 10:00 PM",
  },
  {
    title: "Certified GYM Center",
    description:
      "Our Certified GYM Center offers state-of-the-art facilities and expert trainers to help you achieve your fitness goals.",
    rules: ["No outside food or drinks", "No pets allowed", "No smoking"],
    guideLines: [
      "Children under 12 must be accompanied by an adult",
      "Use of protective gear is recommended",
      "Respect other members",
    ],
    timings: "Open from 6:00 AM to 10:00 PM",
  },
  {
    title: "Gem Store",
    description:
      "Explore a world of elegance and beauty in our gem store. Discover exquisite jewels and gemstones curated to perfection.",
    rules: null,
    guideLines: null,
    timings: "Open from 10:00 AM to 8:00 PM",
  },
];

export const AllFeatures = () => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(0);

  const handleOpen = (index) => {
    setOpen(true);
    setActive(index);
  };

  const handleClose = () => {
    setOpen(false);
    setActive(0);
  };

  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <div className="container row d-flex justify-content-center">
      {data.map((item, index) => (
        <div
          className="col-lg-4 p-0 d-flex justify-content-center wow fadeInUp"
          onClick={() => {
            handleOpen(index);
          }}
        >
          <Card
            hoverable
            style={{ width: "100%", margin: "20px" }}
            cover={<img alt="example" src={images[index]} />}
          >
            <Card.Meta title={item.title} description={item.description} />
          </Card>
        </div>
      ))}
      <Modal
        title={<h2>{`${addtionalInfo[active]?.title}`}</h2>}
        open={open}
        onOk={handleClose}
        onCancel={handleClose}
      >
        <div >
          <p>{addtionalInfo[active]?.description}</p>
          <h5>Rules:</h5>
          <ul >
            {addtionalInfo[active]?.rules?.map((rule) => (
              <li>{rule}</li>
            ))}
          </ul>
          <h5>Guidelines:</h5>
          <ul>
            {addtionalInfo[active]?.guideLines?.map((guideLine) => (
              <li>{guideLine}</li>
            ))}
          </ul>
          <p>Timings: {addtionalInfo[active]?.timings}</p>
        </div>
      </Modal>
    </div>
  );
};
