import React, { useEffect } from "react";
import { BookingCard } from "../components/BookingCard";
import gym from "../assests/img/services/img6.jpg";
import badminton from "../assests/img/services/img5.jpg";
import pool from "../assests/img/services/img3.jpg";
import cricket from "../assests/img/services/img1.jpg";
import { Outlet } from "react-router";
import WOW from "wowjs";

const data = [
  {
    pic: pool,
    name: "Swimming Pool",
    description:
      "Enjoy a refreshing swim in our pristine pool, perfect for both laps and leisure.",
    price: 80,
    active: true,
    path: "/swimmingPool"
  },
  {
    pic: gym,
    name: "Gymnasium",
    description: "Our gym features the latest equipment and expert trainers to help you achieve your fitness goals in an inspiring environment.",
    price: 100,
    active:false,
    path: "/gym"
  },
  {
    pic: badminton,
    name: "Badminton Court",
    description:
      "Play on our professional-grade indoor badminton court, ideal for all skill levels and available year-round.",
    price: 50,
    active:false,
    path: "/badminton"
  },
  {
    pic: cricket,
    name: "Cricket Court",
    description: "Improve your cricket skills on our advanced indoor practice pitch, suitable for all players from beginners to pros.",
    price: 70,
    active:false,
    path: "/cricket"
  },
];

export const ReservationsPage = () => {
  useEffect(()=>{
    new WOW.WOW().init();
  },[])
  return (
    <div className="container">
      <div className="row">
        {data.map((item,i) => {
          return (
            <div key={i} className="col-md-6 wow fadeInUp">
              <BookingCard data={item} />
            </div>
          );
        })}
      </div>
      <Outlet />
    </div>
  );
};
