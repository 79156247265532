import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { ApiClient } from "../../utils/ApiClient";
import { useNavigate } from "react-router-dom";

export const ViewProfile = () => {
  const [options, setOptions] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const Navigate=useNavigate();

  const fetchUsers = async () => {
    const response = await ApiClient.get("/user/getUsers");
    setUserDetails(response.data);
  };

  useEffect(() => {
    fetchUsers();
  }, []);


  const handleChange = (value) => {
    // console.log(`selected ${value}`);
    Navigate(`/profile/${value}`);
  }

  useEffect(() => {
    const optionsList = userDetails.map((user) => (
      <Select.Option key={user?.membershipNumber} value={user._id}>
        {(user.membershipNumber?("(" + user.membershipNumber+")"+" "):"")+user.email  }
      </Select.Option>
    ));
    setOptions(optionsList);
  }, [userDetails]);

  return (
    <div>
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select a person"
        onChange={handleChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options}
      </Select>
    </div>
  );
};
