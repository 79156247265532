import React, { useEffect } from "react";
import { Button, Table, Tag } from "antd";
import ModalEdit from "./ModalEdit";
import { useState } from "react";
import { Modal, Input } from "antd";
import { Form, InputNumber } from "antd";
import { Row, Col } from "antd";
import { Select } from "antd";
import { DatePicker, Space } from "antd";
import { ApiClient } from "../../utils/ApiClient";
import toast from "react-hot-toast";

const GymPackage = () => {
  const [formBuy, setFormBuy] = useState([]);
  const [packageData, setPackageData] = useState();
  const [openBuyModal, setOpenBuyModal] = useState(false);
  const [packageOption, setPackageOption] = useState([]);
  const [tempKey, setTempKey] = useState();
  const [emails, setEmails] = useState([]);
  const [datas, setDatas] = useState([]);
  const [attitude, setAttitude] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const [isloading, setIsLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [componentSize, setComponentSize] = useState("large");
  const [form, setForm] = useState([]);
  const [newValue, setNewValue] = useState();

  const handleSubmitBuy = async () => {
    setOpenBuyModal(false);
    // //console.log({
    //   email: formBuy.email,
    //   startDate: formBuy.start,
    //   package: formBuy.package,
    //   endDate: "endDate",
    //   price: "Price",
    //   type: "Gym",
    // });
  };

  const fetchData = async () => {
    setIsLoading(true);
    await ApiClient
      .get("/gym-packages/")
      .then((response) => {
        setIsLoading(false);
        setPackageData(response.data);
        setPackageOption(
          response.data.map((item) => ({ value: item._id, label: item.name }))
        );
      })
      .catch((error) => {
        //console.log(error);
        setIsLoading(false);
        toast.error("Failed to fetch data");
      });
  };


  const fetchUsers = async () => {
    await ApiClient
      .get("/user/email")
      .then((response) => {

        setEmails(
          response.data.map((item) => ({
            value: item.email,
            label: item.email,
          }))
        );
      })
      .catch((error) => {
        //console.log(error);
        toast.error("Failed to fetch data");
      });
  };

  const handleDelete = async (id) => {
    //console.log(id);
    await ApiClient
      .delete(`/gym-packages/${id}`)
      .then((response) => {
        //console.log(response);
        fetchData();
        toast.success("Package Deleted Successfully");
      })
      .catch((error) => {
        // console.error(error);
        toast.error("Failed to delete package");
      });
  };

  const handleOk = async () => {
    setIsModalOpen(false);
    await ApiClient
      .put(`/gym-packages/${datas._id}`, {
        value: newValue,
        attitude: attitude,
      })
      .then((response) => {
        //console.log(response);
        fetchData();
        toast.success("Package Updated Successfully");
      })
      .catch((error) => {
        // console.error(error);
        toast.error("Failed to update package");
      });
  };

  const handleSubmitAdd = async () => {
    setOpenAddModal(false);
    await ApiClient
      .post(`/gym-packages/`, {
        name: form.name,
        rate: form.rate,
      })
      .then((response) => {
        fetchData();
        toast.success("Package Added Successfully");
      })
      .catch((error) => {
        // console.error(error);
        toast.error("Failed to add package");
      });
  };


  const columns = [
    // {
    //   title: "Package Id",
    //   dataIndex: "_id",
    //   key: "_id",
    //   // render: (text, record, index) => <span>{index + 1}</span>,
    // },

    {
      title: "Package Name",
      dataIndex: "displayName",
      key: "displayName",
      // render: (text, key) => (
      //   <a id="name" onClick={(e) => handleClick(key, e.target.id)}>
      //     {text}
      //   </a>
      // ),
    },
    {
      title: "Price",
      dataIndex: "rate",
      key: "Price",
      render : (text,record) => <span>{`Rs.${record.rate}/=`}</span>
      // render: (text, key) => (
      //   <a id="rate" onClick={(e) => handleClick(key, e.target.id)}>
      //     Rs.{text}/=
      //   </a>
      // ),
    },
    // {
    //   title: "Buy",
    //   key: "buy",
    //   render: (text, key) => (
    //     <Button
    //       type="primary"
    //       onClick={() => {
    //         handleBuy(key._id);
    //       }}
    //     >
    //       Buy
    //     </Button>
    //   ),
    // },
    // {
    //   title: "Delete",
    //   key: "delete",
    //   render: (text, key) => (
    //     <Button
    //       onClick={() => {
    //         handleDelete(key._id);
    //       }}
    //     >
    //       Delete
    //     </Button>
    //   ),
    // },
    {
      title: "Pax",
      dataIndex: "pax",
      key: "pax",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    }
  ];

  const onChange = (value) => {
    setFormBuy({ ...formBuy, email: value });
  };
  const onSearch = (value) => {
    //console.log("search:", value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  
  const changeDate = (date, dateString) => {
    //console.log(date[0], dateString);
    setFormBuy({ ...formBuy, start: dateString });
  };

  
  const checkQuantity = (rule, value, callback) => {
    if (value <= 0) {
      callback("Quantity must be greater than 0");
    } else {
      callback();
    }
  };

  const handleClick = (key, data) => {
    setDatas(key);
    setAttitude(data);
    setIsModalOpen(true);
    setDatas(key);
    setNewValue(key[data]);
  };
  const onFormLayoutChange = ({ size }) => {
    setComponentSize("large");
  };

  const handleAdd = async () => {
    setOpenAddModal(true);
  };

  useEffect(() => {
    fetchUsers();
  }, [tempKey]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container">
      <ModalEdit
        datas={datas}
        attitude={attitude}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleOk={handleOk}
        newValue={newValue}
        setNewValue={setNewValue}
      />
      <Table columns={columns} dataSource={packageData} loading={isloading} scroll={{x:1300}}/>
      {/* <Button onClick={() => handleAdd()}>Add New Package</Button> */}
      <Modal
        title="Modal 1000px width"
        centered
        open={openAddModal}
        onOk={() => handleSubmitAdd()}
        onCancel={() => {
          setOpenAddModal(false);
          setTempKey("");
        }}
        width={500}
      >
        <div>
          <Row>
            <Col span={20} offset={0}>
              <Form
                labelCol={{
                  span: 15,
                }}
                wrapperCol={{
                  span: 40,
                }}
                layout="horizontal"
                initialValues={{
                  size: componentSize,
                }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
                style={{
                  maxWidth: 600,
                }}
              >
                <Form.Item label="Package Name">
                  <Input
                    value={form.name}
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                  />
                </Form.Item>
                <Form.Item label="Rate" rules={[{ validator: checkQuantity }]}>
                  <InputNumber
                    min={1}
                    value={form.rate}
                    onChange={(value) => setForm({ ...form, rate: value })}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        title="Modal 1000px width"
        centered
        open={openBuyModal}
        onOk={() => handleSubmitBuy()}
        onCancel={() => setOpenBuyModal(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={20} offset={0}>
              <Form
                labelCol={{
                  span: 15,
                }}
                wrapperCol={{
                  span: 40,
                }}
                layout="horizontal"
                initialValues={{
                  size: componentSize,
                }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
                style={{
                  maxWidth: 600,
                }}
              >
                <Form.Item label="User Email">
                  <Select
                    showSearch
                    placeholder="Select a person"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={filterOption}
                    options={emails}
                  />
                </Form.Item>
                <Form.Item label="Start From">
                  <DatePicker onChange={changeDate}  />
                </Form.Item>
                <Form.Item label="Package Name">
                  <Select
                    defaultValue={tempKey}
                    onChange={(value) =>
                      setFormBuy({ ...formBuy, package: value })
                    }
                    options={packageOption}
                  />
                </Form.Item>
                <Form.Item label="Total Budget">
                  <Input
                    readOnly
                    value="Rs.1000/="
                    // onChange={(e) =>
                    //   setFormBuy({ ...formBuy, name: e.target.value })
                    // }
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default GymPackage;
