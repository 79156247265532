import React from 'react'
import { Timetable } from '../components/Timetable'

export const Classes = () => {
  return (
    <div>
        <Timetable />
    </div>
  )
}
