import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import WOW from "wowjs";
import calendar from "../assests/img/home/calendar.png";
import fastFood from "../assests/img/home/fast-food.png";
import { Button } from "antd";

export const MainServices = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const navigate = useNavigate();

  return (
    <div style={{ zIndex: `20 !important`, marginTop: "-50px" }}>
      <div className="container gym-className mb-5" style={{ marginTop: "10px" }}>
        <div className="row px-3 h-100">
          <div className="col-md-6 p-0 ">
            <div className="h-100  wow slideInLeft gym-className-box d-flex flex-column align-items-end justify-content-start bg-primary text-right text-white py-5 px-5">
              <div className="d-flex justify-content-start w-100">
                <div
                  style={{
                    scale: "1",
                    display: "block",
                    position: "absolute",
                    marginTop: "0px",
                    marginLeft: "-150px",
                    opacity: "40%",
                  }}
                >
                  <img
                    src={calendar}
                    color="white"
                    alt="calendar"
                    style={{ width: "300px" }}
                  />
                </div>
              </div>
              <h3 className="display-4 mb-3 text-white font-weight-bold wow fadeInUp">
                Reservations
              </h3>
              <div className="d-flex flex-column justify-content-between w-100 h-100">
                <p className=" wow fadeInUp">
                  Ready to play? Secure your spot now and enjoy top-notch
                  facilities and a fantastic sporting experience. Click "Make a
                  Reservation" to get started!
                </p>
                <Button
                  size="large"
                  onClick={() => navigate("/booking")}
                  className="bg-secondary text-white font-weight-bold text-lg-center"
                >
                  Make a Reservation
                </Button>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 h-100">
            <div className="h-100 wow slideInRight gym-className-box d-flex flex-column align-items-start justify-content-start bg-secondary text-left text-white py-5 px-5">
              <div className="d-flex justify-content-end w-100">
                <div
                  style={{
                    scale: "1",
                    display: "block",
                    position: "absolute",
                    marginTop: "0px",
                    marginRight: "-150px",
                    opacity: "40%",
                  }}
                >
                  <img
                    src={fastFood}
                    color="white"
                    alt="calendar"
                    style={{ width: "300px" }}
                  />
                </div>
              </div>
              <h3 className="display-4 mb-3 text-white font-weight-bold wow fadeInUp">
                Dining
              </h3>
              <div className="d-flex flex-column w-100 justify-content-between">
                <p className=" wow fadeInUp">
                  Join us for a delightful dining experience! Whether you're
                  here for sports or just a great meal, our on-site restaurant
                  offers something for everyone.  
                </p>
                <Button
                  size="large"
                  className="bg-primary text-white font-weight-bold text-lg-center"
                >
                  Coming Soon!
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
