import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Layout, Menu, theme } from "antd";
import PoolBooking from "./PoolBooking";
import { BiSolidReport } from "react-icons/bi";
import { BadmintonBooking } from "./BadmintonBooking";
import { IoMdPersonAdd } from "react-icons/io";
import { FaBook } from "react-icons/fa6";
import { FaCamera } from "react-icons/fa6";
import { BiSolidPackage } from "react-icons/bi";
import { FaCameraRetro } from "react-icons/fa";
import { MdSportsCricket } from "react-icons/md";
import PoolPackage from "./PoolPackage";
import { TbCricket } from "react-icons/tb";
import { FaTableTennis } from "react-icons/fa";
import FoodMenu from "./FoodMenu";
import { OrderHistory } from "../OrderHistory";
import { Gym } from "../../pages/Gym";
import GymPackage from "./GymPackage";
import toast from "react-hot-toast";
import { GymSubscriptions } from "./GymSubscriptions";
import { CricketBooking } from "./CricketBooking";
import { PoolSubscriptions } from "./PoolSubscriptions";
import { FaUserTie } from "react-icons/fa6";
import { Analytics } from "./Analytics";
import { CustomBill } from "./CustomBill";
import { PiStudentFill } from "react-icons/pi";
import { IoMdDownload } from "react-icons/io";
import { IoSchool } from "react-icons/io5";
import { GymSchool } from "./GymSchool";
import { Members } from "./Members";
import { ViewProfile } from "./ViewProfile";
import { useMediaQuery } from "react-responsive";
import AdminHome from "./AdminHome";
import { AttendenceReport } from "./AttendenceReport";
const { Header, Content, Footer, Sider } = Layout;

const items = [
  {
    key: "0",
    icon: <i className="fa-solid fa-home"></i>,
    label: "Home",
  },
  // {
  //   key: "1",
  //   icon: <i className="fa-solid fa-utensils"></i>,
  //   label: "Restaurent"
  // },
  {
    key: "2",
    icon: <i className="fa-solid fa-dumbbell "></i>,
    label: "Gymnasium",
    children: [
      {
        key: "21",
        label: "Subscriptions",
        icon: <IoMdDownload />,
      },

      {
        key: "23",
        label: "School Members",
        icon: <IoSchool />,
      },
      {
        key: "24",
        label: "Membership No",
        icon: <IoMdPersonAdd />,
      },
      // {
      //   key: "25",
      //   label: "Attendance Report",
      //   icon: <BiSolidReport />,
      // },
      {
        key: "22",
        label: "Packages",
        icon: <BiSolidPackage />,
      },
    ],
  },
  {
    key: "31",
    icon: <FaTableTennis />,
    label: "Badminton",
  },
  {
    key: "41",
    icon: <MdSportsCricket />,
    label: "Cricket",
  },
  {
    key: "5",
    icon: <i className="fa-solid fa-person-swimming "></i>,
    label: "Swimming Pool",
    children: [
      {
        key: "51",
        label: "Reservations",
        icon: <FaBook />,
      },
      {
        key: "52",
        label: "Subscriptions",
        icon: <IoMdDownload />,
      },
      {
        key: "53",
        label: "Packages",
        icon: <BiSolidPackage />,
      },
    ],
  },
  {
    key: "85",
    icon: <BiSolidReport />,
    label: "Custom Billing",
  },
  {
    key: "61",
    icon: <i className="fa-regular fa-calendar-check"></i>,
    label: "Reports",
  },
  {
    key: "71",
    icon: <FaUserTie/>,
    label: "Profile View",
  },
];

const Sidebar = () => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1300px)" });
  const inputRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [active, setActive] = useState("0");

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  useEffect(() => {});

  return (
    <Layout
      style={{
        minHeight: "100vh",
        marginTop: "-64px",
      }}
    >
      <Sider
        collapsible
        collapsed={!isBigScreen}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          defaultSelectedKeys={["11"]}
          mode="inline"
          items={items}
          onSelect={(value) => setActive(value.key)}
        />
      </Sider>
      <Layout>
        <Content
          style={{
            margin: "0 16px",
          }}
        >
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {active == "0" ? (
              <AdminHome />
            ) : active == "11" ? (
              <FoodMenu />
            ) : active == "12" ? (
              <OrderHistory />
            ) : active == "21" ? (
              <GymSubscriptions />
            ) : active == "22" ? (
              <GymPackage />
            ) : active == "31" ? (
              <BadmintonBooking />
            ) : active == "41" ? (
              <CricketBooking />
            ) : active == "51" ? (
              <PoolBooking />
            ) : active == "52" ? (
              <PoolSubscriptions />
            ) : active == "53" ? (
              <PoolPackage />
            ) : active == "61" ? (
              <Analytics />
            ) : active == "85" ? (
              <CustomBill />
            ) : active == "23" ? (
              <GymSchool />
            ) : active == "24" ? (
              <Members />
            ) : active == "71" ? (
              <ViewProfile />
            ) : active == "25" ? (
              <AttendenceReport />
            ) : (
              <div></div>
            )}
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          {/* <Button >Check-Out</Button> */}
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Sidebar;
