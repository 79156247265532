import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export const Contact = () => {
  const form = useRef();
  const [capatcha, setCapatcha] = useState(false);
  function onChange(value) {
    setCapatcha(value === null ? false : true);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    emailjs
      .sendForm(
        "service_i6rxghv",
        "template_c4eyx4t",
        form.current,
        "6cz02AIpbo38auJQD"
      )
      .then(
        (result) => {
          //console.log(result.text);
          // null all values
          form.current.reset();
        },
        (error) => {
          //console.log(error.text);
        }
      );
  };

  return (
    <div className="container pt-5">
      <div className="d-flex flex-column text-center mb-5">
        <h4 className="text-primary font-weight-bold">Get In Touch</h4>
        <h4 className="display-4 font-weight-bold">
          Phone Number Us For Any Query
        </h4>
      </div>
      <div className="row px-3 pb-2">
        {/* Your address, phone, and phone details */}
        <div className="col-sm-4 text-center mb-3">
          <i className="fa fa-2x fa-map-marker-alt mb-3 text-primary"></i>
          <h4 className="font-weight-bold">Address</h4>
          <p>73, Barns Rathwaththa Mawatha, Balangoda.</p>
        </div>
        <div className="col-sm-4 text-center mb-3">
          <i className="fa fa-2x fa-phone-alt mb-3 text-primary"></i>
          <h4 className="font-weight-bold">Phone</h4>
          <p>+94 77 346 8778</p>
        </div>
        <div className="col-sm-4 text-center mb-3">
          <i className="far fa-2x fa-envelope mb-3 text-primary"></i>
          <h4 className="font-weight-bold">Phone Number</h4>
          <p>boostsports5@gmail.com</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pb-5">
          <iframe
            style={{ width: "100%", height: "392px" }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.9767589952344!2d80.69667287571349!3d6.6498023216812525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae3f32dded2f5e7%3A0xf167e2a74f8f6996!2sBoost%20Sports!5e0!3m2!1sen!2slk!4v1711393495075!5m2!1sen!2slk"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
        <div className="col-md-6 pb-5">
          <div className="contact-form">
            <div id="success"></div>
            <form onSubmit={handleSubmit} noValidate ref={form}>
              <div className="control-group">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  // value={formData.name}
                  // onChange={handleInputChange}
                  required
                />
                <p className="help-block text-danger"></p>
              </div>
              <div className="control-group">
                <input
                  type="phone"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder="Your Phone Number (07X XXXXXXX)"
                  // value={formData.phone}
                  // onChange={handleInputChange}
                  required
                />
                <p className="help-block text-danger"></p>
              </div>
              <div className="control-group">
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  name="subject"
                  placeholder="Subject"
                  // value={formData.subject}
                  // onChange={handleInputChange}
                  required
                />
                <p className="help-block text-danger"></p>
              </div>
              <div className="control-group">
                <textarea
                  className="form-control"
                  rows="6"
                  id="message"
                  name="message"
                  placeholder="Message"
                  // value={formData.message}
                  // onChange={handleInputChange}
                  required
                ></textarea>
                <p className="help-block text-danger"></p>
              </div>
              <ReCAPTCHA
                sitekey="6LdTFdgpAAAAADonqb3_AYWzTW0K72YEsEklHC6m"
                onChange={onChange}
              />
              <div>
                <button
                  disabled={
                    form.current?.name?.value === "" ||
                    form.current?.phone?.value === "" ||
                    form.current?.subject?.value === "" ||
                    form.current?.message?.value === "" ||
                    !capatcha
                  }
                  className="btn btn-outline-primary"
                  type="submit"
                  id="sendMessageButton"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
