import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./BookingCalendar.css";
import { Button, Checkbox, Modal, Radio, Select } from "antd";
import { Form, Input, InputNumber } from "antd";
import { Row, Col } from "antd";
import { DatePicker, Space } from "antd";
import { useSelector } from "react-redux";
import { ApiClient } from "../utils/ApiClient";
import React, { useEffect, useState } from "react";
import "moment-timezone";
import toast from "react-hot-toast";
import { PRICES } from "../utils/Prices";
import { useMediaQuery } from "react-responsive";

const CourtBooking = ({ type }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  const [eventsData, setEventsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [componentSize, setComponentSize] = useState("large");
  const [checkInTime, setCheckInTime] = useState(null);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [dateNew, setDateNew] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [views, setViews] = useState(["week", "day"]);

  useEffect(() => {
    setViews(isBigScreen ? ["week", "day"] : ["day"]);
  }, [isBigScreen, isTabletOrMobile, isPortrait, isRetina, isDesktopOrLaptop]);

  moment.tz.setDefault("Asia/Colombo");
  const localizer = momentLocalizer(moment);

  useEffect(() => {
    fetchData();
  }, [type]);

  const getPhone = useSelector((state) => state?.user?.user?.phone);
  const [withMachine, setWithMachine] = useState(null);

  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (valueIn !== null) {
      handleChangeI(valueIn);
    }
    if (valueOut !== null) {
      handleChangeO(valueOut);
    }
  }, [dateNew]);

  const [valueIn, setValueIn] = useState(null);
  const [valueOut, setValueOut] = useState(null);

  const handleChangeI = async (value) => {
    setValueIn(value);
    setStartHour(value + 1);
    const newDate = new Date(dateNew);
    const newDate1 = new Date(newDate.setHours(value));
    const newDate2 = new Date(newDate1.setMinutes(0));
    setCheckInTime(newDate2);
  };

  const handleChangeO = (value) => {
    setValueOut(value);
    const newDate = new Date(dateNew);
    const newDate1 = new Date(newDate.setHours(value));
    const newDate2 = new Date(newDate1.setMinutes(0));
    setCheckOutTime(newDate2);
  };

  const fetchData = async () => {
    try {
      const response = await ApiClient.get(`/${type}-event`);
      //console.log(response.data);
      const formattedEvents = response.data.map((event) => ({
        id: event.id,
        title: event.title,
        start: new Date(event.start),
        end: new Date(event.end),
      }));
      //console.log(formattedEvents);
      setEventsData(formattedEvents);
    } catch (error) {
      // console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
    }
  };

  const onChange = (date, dateString) => {
    setSelectedDate(date);
    setDateNew(dateString);
  };

  useEffect(() => {
    setPhone(getPhone);
  }, [getPhone]);

  const formats = {
    dayHeaderFormat: (date, culture, localizer) =>
      localizer.format(date, "dddd, MMMM Do", culture), // Format day header
    timeGutterFormat: (date, culture, localizer) =>
      localizer.format(date, "HH:mm", culture), // Format time as HH:mm
  };

  const user = useSelector((state) => state?.user.user);

  const today = moment().startOf("day");
  const startOfWeek = today.clone().startOf("isoWeek");

  const onFormLayoutChange = ({ size }) => {
    setComponentSize("large");
  };

  const [optionsIn, setOptionsIn] = useState([]);
  const [optionsOut, setOptionsOut] = useState([]);

  const generateOptionsIn = () => {
    const options = [];
    for (let hour = 6; hour <= 22; hour++) {
      const label = `${hour.toString().padStart(2, "0")}:00:00`;
      options.push({ value: hour, label });
    }
    return options;
  };
  const [startHour, setStartHour] = useState(6);

  const generateOptionsOut = () => {
    const options = [];
    for (let hour = startHour; hour <= 23; hour++) {
      const label = `${hour.toString().padStart(2, "0")}:00:00`;
      options.push({ value: hour, label });
    }
    return options;
  };

  useEffect(() => {
    setOptionsOut(generateOptionsOut());
  }, [startHour]);

  useEffect(() => {
    setOptionsIn(generateOptionsIn());
  }, []);

  const handleSubmit = async () => {
    const body = {
      createdBy: user?._id,
      startTime: checkInTime,
      endTime: checkOutTime,
      phone: phone,
      withMachine: withMachine,
    };
    //console.log(body);
    setOpen(false);
    try {
      const response = await ApiClient.post(`/${type}-event`, body);
      setCheckInTime(null);
      setCheckOutTime(null);
      setPhone(getPhone);
      setDateNew(null);
      setCheckInTime(null);
      setCheckOutTime(null);
      setSelectedDate(null);
      setWithMachine(null);
      fetchData();
      toast.success("Reservation made successfully");
    } catch (error) {
      // console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-center my-2">
        <Button
          type="primary"
          className=""
          onClick={() => {
            setOpen(true);
            setCheckInTime(null);
            setCheckOutTime(null);
          }}
        >
          + New Reservation
        </Button>
      </div>
      <Calendar
        views={["week", "day"]}
        // views = {["week", "day"]}
        selectable={false}
        localizer={localizer}
        events={eventsData && eventsData}
        defaultDate={startOfWeek.toDate()}
        style={{ height: "100vh" }}
        // onSelectEvent={(event) => alert(event.title)}
        // longPressThreshold={20}
        defaultView={"week"}
        onView={(view) => console.log(view)}
        step={60}
        formats={formats}
        min={new Date(0, 0, 0, 6, 0, 0)}
        max={new Date(0, 0, 0, 22, 0, 0)}
        timeslots={1}
        className="no-row-spacing"
        enableAutoScroll
      />

      <Modal
        title={`Make a Reservation for ${type} court`}
        centered
        open={open}
        onOk={() => handleSubmit()}
        okButtonProps={{
          disabled:
            !checkInTime ||
            !checkOutTime ||
            !dateNew ||
            !phone ||
            (withMachine == null && type == "cricket") ||
            valueOut <= valueIn,
        }}
        onCancel={() => {
          setOpen(false);
          setCheckInTime(null);
          setCheckOutTime(null);
          setDateNew(null);
          setPhone(getPhone);
          setValueIn(null);
          setValueOut(null);
          setSelectedDate(null);
          setWithMachine(null);
        }}
      >
        <div>
          <Row justify="center">
            <Col span={24} offset={0} className="w-100">
              <Form
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 18,
                }}
                layout="horizontal"
                initialValues={{
                  size: componentSize,
                }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
                labelAlign="left"
                style={{
                  width: "100%",
                  // maxWidth: 600,
                }}
              >
                <Form.Item label="User Name">
                  <Input
                    style={{ width: "100%" }}
                    value={user?.name}
                    readOnly
                  />
                </Form.Item>

                <Form.Item label="Phone Number">
                  <Input
                    type="number"
                    style={{ width: "100%" }}
                    value={phone}
                    placeholder="07XXXXXXXX"
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Item>

                <Form.Item label="Date">
                  <DatePicker
                    value={selectedDate}
                    onChange={onChange}
                    style={{ width: "100%" }}
                    disabledDate={(current) =>
                      current && current < moment().startOf("day")
                    }
                  />
                </Form.Item>

                <Form.Item label="Check-In">
                  <Select
                    defaultValue="Select Time"
                    style={{ width: "100%" }}
                    onChange={handleChangeI}
                    value={checkInTime && checkInTime.getHours()}
                    options={optionsIn}
                  />
                </Form.Item>
                <Form.Item label="Check-Out">
                  <Select
                    defaultValue="Select Time"
                    style={{ width: "100%" }}
                    onChange={handleChangeO}
                    value={checkOutTime && checkOutTime.getHours()}
                    options={optionsOut}
                    disabled={!checkInTime}
                  />
                </Form.Item>
                {type === "cricket" ? (
                  <div>
                    {" "}
                    <Form.Item label="Options">
                      <Select
                        defaultValue="Select Time"
                        style={{ width: "100%" }}
                        placeholder="Select the category"
                        options={[
                          { value: true, label: "With Balling Machine" },
                          { value: false, label: "Without Balling Machine" },
                        ]}
                        onChange={(e) => setWithMachine(e)}
                        value={withMachine}
                      />
                    </Form.Item>
                    <Col span={24} offset={0}>
                      <div className="d-flex align-items-end flex-column">
                        <h4>
                          Total: Rs.
                          {valueOut === null
                            ? 0
                            : (withMachine
                                ? PRICES.CRICKET_COURT_WITH_BALLING_MACHINE
                                : PRICES.CRICKET_COURT_WITHOUT_BALLING_MACHINE) *
                              (valueOut - valueIn)}
                          /=
                        </h4>
                      </div>
                    </Col>
                  </div>
                ) : type == "badminton" ? (
                  <div>
                    <div>
                      <Col span={24} offset={0}>
                        <div className="d-flex align-items-end flex-column">
                          <h4>
                            Total: Rs.
                            {valueOut === null
                              ? 0
                              : PRICES.BADMINTON_COURT * (valueOut - valueIn)}
                            /=
                          </h4>
                        </div>
                      </Col>
                    </div>
                  </div>
                ) : null}
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};
export default CourtBooking;
