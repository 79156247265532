import { Button } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import WOW from "wowjs";
export const BMI = () => {
  const [form, setForm] = useState([]);
  const [bmi, setBmi] = useState(0);
  const [status, setStatus] = useState(null);

  const handleReset = () => {
    setForm({ weight: "", height: "", age: "", gender: ""});
    setBmi(0);
    updateColorBasedOnBMI(0);
  };

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const handleCalculate = async() => {
    let height = form.height / 100;
    let bmi = form.weight / (height * height);
    setBmi(bmi.toFixed(1));
    updateColorBasedOnBMI(bmi);
    updateState(bmi);
  };

  function updateState (bmi){
    if (bmi < 16) {
      setStatus("Severe Thinness");
    } else if (bmi >= 16 && bmi < 17) { 
      setStatus("Moderate Thinness");
    }
    else if (bmi >= 17 && bmi < 18.5) {
      setStatus("Mild Thinness");
    }
    else if (bmi >= 18.5 && bmi < 25) {
      setStatus("Normal");
    }
    else if (bmi >= 25 && bmi < 30) {
      setStatus("Overweight");
    }
    else if (bmi >= 30 && bmi < 35) {
      setStatus("Obese Class I");
    }
    else if (bmi >= 35 && bmi < 40) {
      setStatus("Obese Class II");
    }
    else if (bmi >= 40) {
      setStatus("Obese Class III");
    }    
  }
  function updateColorBasedOnBMI(bmiValue) {
    let backgroundColor;
    if (bmiValue === 0) {
      backgroundColor = "black";
    } else if (bmiValue < 16) {
      backgroundColor = "#990000"; // Dark red
    } else if (bmiValue >= 16 && bmiValue < 17) {
      backgroundColor = "#B35900"; // Dark orange
    } else if (bmiValue >= 17 && bmiValue < 18.5) {
      backgroundColor = "#CC9900"; // Dark yellow
    } else if (bmiValue >= 18.5 && bmiValue < 25) {
      backgroundColor = "#006600"; // Dark green
    } else if (bmiValue >= 25 && bmiValue < 30) {
      backgroundColor = "#B35900"; // Dark orange
    } else if (bmiValue >= 30 && bmiValue < 35) {
      backgroundColor = "#330033"; // Dark purple
    } else if (bmiValue >= 35 && bmiValue < 40) {
      backgroundColor = "#660033"; // Dark pink
    } else if (bmiValue >= 40) {
      backgroundColor = "#990000"; // Dark red
    }
    document.getElementById("bmiContainer").style.backgroundColor =
      backgroundColor;
  }
  return (
    <div>
      <div className="container-fluid position-relative bmi my-5 ">
        <div className="">
          <div className="row  align-items-center ">
            <div className="col-md-6">
              <div className="pr-md-3 d-none d-md-block wow fadeInLeft">
                <h4 className="text-primary">Body Mass Index </h4>
                <h4 className="display-4 text-white font-weight-bold mb-4">
                  Whate is BMI?
                </h4>
                <p className="m-0 text-white">
                  Vero elitr lorem magna justo magna justo at justo est ipsum
                  sed clita lorem dolor ipsum sed. Lorem sea lorem vero. Sanct
                  dolor clita clita rebum kasd magna erat diam
                </p>
              </div>
            </div>
            <div
              id="bmiContainer"
              className="col-md-6  py-5"
              style={{ backgroundColor: "black" }}
            >
              <div className="py-5 px-3 wow fadeInRight">
                <h1 className="mb-4 text-white">Calculate your BMI</h1>
                {/* <form onSubmit={()=>console.log("A")}> */}
                <div className="form-row">
                  <div className="col form-group">
                    <input
                      type="number"
                      className="form-control form-control-lg bg-light "
                      placeholder="Weight (KG)"
                      value={form.weight}
                      onChange={(e) =>
                        setForm({ ...form, weight: e.target.value })
                      }
                    />
                  </div>
                  <div className="col form-group">
                    <input
                      type="number"
                      className="form-control form-control-lg bg-light "
                      placeholder="Height (CM)"
                      value={form.height}
                      onChange={(e) =>
                        setForm({ ...form, height: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col form-group">
                    <input
                      type="number"
                      value={form.age}
                      className="form-control form-control-lg bg-light "
                      placeholder="Age"
                      onChange={(e) =>
                        setForm({ ...form, age: e.target.value })
                      }
                    />
                  </div>
                  <div className="col form-group">
                    <select className="custom-select rounded custom-select-lg bg-light text-muted" 
                    onChange={(e) =>
                      setForm({ ...form, gender: e.target.value })
                    }
                    value={form.gender}
                    defaultValue={""}
                    >
                      <option value={""}>Gender</option>
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col">
                    <input
                      type="button"
                      onClick={() => handleCalculate()}
                      className="btn btn-lg btn-block btn-light border-light text-primary font-weight-bold"
                      value={"Calculate Now"}
                    />
                  </div>
                </div>
                {bmi !== 0 && (
                  <div className="form-row">
                    <div className="col">
                      <h1 className="text-white">BMI is: {bmi} & It is {status}</h1>{" "} 
                      <Button type="primary" onClick={() => handleReset()}>
                        Reset
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
