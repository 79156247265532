import React, { useEffect } from "react";
import { Button, DatePicker, Form, Select, Space, Table, Tag } from "antd";
import ModalEdit from "./ModalEdit";
import { useState } from "react";
import CheckInModal from "./CheckInModal";
import { Modal, Input } from "antd";
import { useRef } from "react";
import dayjs from "dayjs";
import { ApiClient } from "../../utils/ApiClient";
import { DeleteFilled } from "@ant-design/icons";
import CheckOutModal from "./CheckOutModal";
import { Bill } from "../../utils/Bill";
import { BarcodeIdGenerate } from "../../utils/BarcodeIdGenerate";
import toast from "react-hot-toast";
import moment from "moment";
import { Navigate, momentLocalizer } from "react-big-calendar";
import { useSelector } from "react-redux";
import { getUser } from "../../store/slices/userSlice";
import Filter from "./Filter";
import StatusTag from "./StatusTag";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { TfiReload } from "react-icons/tfi";
import generatePDF from "react-to-pdf";

const PoolBooking = () => {
  const Navigate = useNavigate();
  const targetRef = React.useRef(null);
  const [isCheckOut, setIsCheckOut] = useState(false);
  const [checkInOpen, setCheckInOpen] = useState(false);
  const [checkInKey, setCheckInKey] = useState([]);
  const [data, setData] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [discountValue, setDiscountValue] = React.useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [newValue, setNewValue] = useState();
  const [outData, setOutData] = useState("");
  const [datas, setDatas] = useState([]);
  const [attitude, setAttitude] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [checkOutOpen, setCheckOutOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [rate, setRate] = useState();
  moment.tz.setDefault("Asia/Colombo");
  const localizer = momentLocalizer(moment);

  const [selectedUsageStatus, setSelectedUsageStatus] = useState([
    "Pending",
    "Check-In",
    "Check-Out",
  ]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([
    "Pending",
  ]);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const usageStatuses = [
    {
      key: "Pending",
      value: "Pending",
      label: "Pending",
    },
    {
      key: "Check-In",
      value: "Check-In",
      label: "Check-In",
    },
    {
      key: "Check-Out",
      value: "Check-Out",
      label: "Check-Out",
    },
    {
      key: "Cancelled",
      value: "Cancelled",
      label: "Cancelled",
    },
    {
      key: "Closed",
      value: "Closed",
      label: "Closed",
    },
  ];

  const paymentStatuses = [
    {
      key: "Pending",
      value: "Pending",
      label: "Pending",
    },
    {
      key: "Paid",
      value: "Paid",
      label: "Paid",
    },
  ];

  const [arrayData, setArrayData] = useState([
    {
      count: 1,
      name: "Pool Booking Adults",
      price: 750,
      noOfHours: 100,
    },
    {
      count: 1,
      name: "Pool Booking Child",
      price: 500,
      noOfHours: 100,
    },
  ]);


  const user = useSelector(getUser);

  useEffect(() => {
    setFilteredData(
      data.filter((item) => {
        return (
          (selectedUsageStatus.includes(item.usageStatus) ||
            selectedPaymentStatus.includes(item.paymentStatus)) &&
          dayjs(item.date)?.format("YYYY-MM-DD") ===
            selectedDate?.format("YYYY-MM-DD")
        );
        // return()
      })
    );
  }, [data, selectedDate, selectedPaymentStatus, selectedUsageStatus]);

  useEffect(() => {
    setArrayData([
      {
        count: selectedRecord?.noOfAdult,
        name: "Pool Booking Adults",
        price: rate?.rateForAdult,
        noOfHours:
          Number(selectedRecord?.checkOut?.split(":")[0]) -
          Number(selectedRecord?.checkIn?.split(":")[0]),
      },
      {
        count: selectedRecord?.noOfChild,
        name: "Pool Booking Child",
        price: rate?.rateForChild,
        noOfHours:
          Number(selectedRecord?.checkOut?.split(":")[0]) -
          Number(selectedRecord?.checkIn?.split(":")[0]),
      },
    ]);
  }, [selectedRecord]);

  const inputRef = useRef();

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "_id",
    //   key: "_id",
    //   render: (text) => <a>{text}</a>,
    // },
    {
      title: "User",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (text,record) => record.createdBy ? record.createdBy : record.userName ,
    },

    {
      title: "Booked Date",
      dataIndex: "date",
      key: "date",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Check-In",
      dataIndex: "checkIn",
      key: "checkIn",
    },
    {
      title: "Check-Out",
      dataIndex: "checkOut",
      key: "checkOut",
    },
    {
      title: "No of Child",
      dataIndex: "noOfChild",
      key: "noOfChild",
    },
    {
      title: "No of Adult",
      dataIndex: "noOfAdult",
      key: "noOfAdult",
    },

    {
      title: "Contact_No",
      dataIndex: "contactNumber",
      key: "contactNumber",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "Amount",
      render: (text,record) => <a>{`Rs.${record.amount}/=`}</a>,
    },

    {
      title: "Payment_Status",
      dataIndex: "paymentStatus",
      key: "Payment_Status",
      render: (text) => <StatusTag status={text} />,
    },
    {
      title: "Usage Status",
      dataIndex: "usageStatus",
      key: "Usage_Status",
      render: (text) => <StatusTag status={text} />,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, index) => {
        // console.log(record);
        return (
          <div className="d-flex gap-2">
            {record.usageStatus == "Pending" ? (
              <>
                <Button
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => handleCheckIn(record)}
                >
                  Check In
                </Button>

                {/* <Button
                className="d-flex justify-content-center align-items-center"
                onClick={() => handleEdit(record)}
              >
                <EditFilled />
              </Button> */}
                <Button
                  color="red"
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => handleDelete(record)}
                >
                  <DeleteFilled color="#FF0000" />
                </Button>
              </>
            ) : record.usageStatus == "Check-In" ? (
              <>
                <Button
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => handleCheckOut(record)}
                >
                  Check Out
                </Button>
              </>
            ) : record.subscription == null &&
              record.usageStatus == "Check-Out" ? (
              <>
                <Button
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => handlePayment(record)}
                >
                  Payment
                </Button>
              </>
            ) : null}
          </div>
        );
      },
    },
  ];

  const handleEdit = (record) => {
    setIsEditModalOpen(true);
    setSelectedRecord(record);
    //console.log(record);
  };

  const handleEditClose = () => {
    setIsEditModalOpen(false);
    setSelectedRecord();
  };

  const handleEditSubmit = () => {
    //console.log("Edit Submit");
    setIsEditModalOpen(false);
    setSelectedRecord();
  };

  const handleDelete = (record) => {
    setSelectedRecord(record);
    setIsDeleteModalOpen(true);
    //console.log(record);
  };

  const handleDeleteClose = () => {
    setSelectedRecord();
    setIsDeleteModalOpen(false);
  };

  const handlePrint = async () => {
    await generatePDF(targetRef, { filename: `${barcode}.pdf` });
  };

  const handleDeleteSubmit = () => {
    setIsLoading(true);
    try {
      ApiClient.delete(`/pool-event/${selectedRecord._id}`)
        .then((response) => {
          //console.log(response);
          fetchData();
          setIsLoading(false);
          setSelectedRecord();
          setIsDeleteModalOpen(false);
          toast.success("Record Deleted Successfully");
        })
        .catch((error) => {
          // console.error(error);
          setIsLoading(false);
          toast.error("Failed to delete record");
        });
    } catch (error) {
      // console.error(error);
    }
  };

  const prepareEventTableData = (data) => {
    //console.log(data);
    return data?.map((item) => ({
      ...item,
      createdBy: item.createdBy?.name,
      isActiveGymMember: item.createdBy?.gymSubscription?.subscriptionStatus,
      date: formattedDate(item.startTime),
      checkIn: formattedTime(item.startTime),
      checkOut: formattedTime(item.endTime),
      createdById: item.createdBy?._id,
    }));
  };

  // useEffect(()=>{
  //   console.log(date)
  // },[date])

  const formattedTime = (time) => {
    return dayjs(time)?.format("HH:mm");
  };

  const formattedDate = (date) => {
    return dayjs(date)?.format("YYYY MMM DD");
  };

  const handleCheckIn = (record) => {
    // setCheckInKey({ id: key, qty: 1 });
    setSelectedRecord(record);
    setCheckInOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleClick = (key, data) => {
    setDatas(key);
    setAttitude(data);
    setIsModalOpen(true);
    setDatas(key);
    setNewValue(key[data]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchCheckOut();
  }, [outData]);

  const fetchCheckOut = async () => {
    if (outData.length > 7) {
      setIsCheckOut(false);
      await ApiClient.post(`/pool-event/bandUpdate`, {
        bId: outData,
      })
        .then((response) => {
          //console.log(response);
          toast.success("Checked Out Successfully");
        })
        .catch((error) => {
          // console.error(error);
        });
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    ApiClient.get("/pool-event/")
      .then((response) => {
        //console.log(response.data.events);
        setData(prepareEventTableData(response.data.events));
        setIsLoading(false);
        setRate(response.data.rates);
      })
      .catch((error) => {
        //console.log(error);
        setIsLoading(false);
        toast.error("Failed to fetch data");
      });
  };

  const handleCheckOut = async (record) => {
    setSelectedRecord(record);
    setCheckOutOpen(true);
  };
  const [barcode, setBarcode] = useState();
  const handlePayment = async (record) => {
    setSelectedRecord(record);
    setPaymentModalOpen(true);
    setBarcode(BarcodeIdGenerate());
    //console.log(record);
  };

  const checkDiscount = () => {
    const noOfHours =
      Number(selectedRecord?.checkOut?.split(":")[0]) -
      Number(selectedRecord?.checkIn?.split(":")[0]);
    if (selectedRecord?.isActiveGymMember === "Active") {
      return rate?.discountPerHour * noOfHours;
    } else {
      return 0;
    }
  };

  const handlePaymentCancel = () => {
    setPaymentModalOpen(false);
    setSelectedRecord();
  };

  const handlePaymentSubmit = () => {
    setIsLoading(true);
    handlePrint();
    try {
      ApiClient.put(`/pool-event/payment/${selectedRecord._id}`, {
        cashierId: user._id,
        userId: selectedRecord?.createdById,
        userName: selectedRecord?.userName,
        amount: (selectedRecord.amount-discountValue),
        paymentCategory: "PoolEvent",
        barcode: barcode,
        discount:discountValue,
      })
        .then((response) => {
          //console.log(response);
          // console.log(response);
          fetchData();
          setIsLoading(false);
          setSelectedRecord();
          setPaymentModalOpen(false);
          toast.success("Payment Successful");
        })
        .catch((error) => {
          // console.error(error);
          setIsLoading(false);
          toast.error("Failed to make payment");
        });
    } catch (error) {
      // console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-center gap-2 my-2 ">
        <Button
          type="primary"
          className=""
          onClick={() => {
            Navigate("/booking/swimmingPool/calendar");
          }}
        >
          + New Reservation
        </Button>
        <div>
          <Button type="primary" onClick={()=>fetchData()}>
            <TfiReload />
          </Button>
        </div>
      </div>
      <Filter
        usageStatuses={usageStatuses}
        paymentStatuses={paymentStatuses}
        setSelectedUsageStatus={setSelectedUsageStatus}
        setSelectedPaymentStatus={setSelectedPaymentStatus}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
      />
      <Table columns={columns} dataSource={filteredData} loading={isloading} scroll={{x:1300}} />
      <ModalEdit
        datas={datas}
        attitude={attitude}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleOk={handleOk}
        newValue={newValue}
        setNewValue={setNewValue}
      />
      <Modal
        title="Vertically centered modal dialog"
        centered
        open={isCheckOut}
        onCancel={() => setIsCheckOut(false)}
      >
        <Input
          placeholder="Scan the Code"
          ref={inputRef}
          onChange={(e) => setOutData(e.target.value)}
        />
      </Modal>
      <CheckInModal
        open={checkInOpen}
        setOpen={setCheckInOpen}
        // checkInKey={checkInKey}
        record={selectedRecord}
        fetchData={fetchData}
      />
      <CheckOutModal
        open={checkOutOpen}
        setOpen={setCheckOutOpen}
        record={selectedRecord}
        fetchData={fetchData}
      />

      <Modal
        title="Edit Pool Booking"
        open={isEditModalOpen}
        onOk={handleEditSubmit}
        onCancel={handleEditClose}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
        >
          <Form.Item label="User">
            <Input value={selectedRecord?.createdBy} disabled />
          </Form.Item>
          <Form.Item label="Booked Date">
            <DatePicker value={dayjs(selectedRecord?.date)} disabledDate={(current) => current && current < moment().startOf("day")} />
          </Form.Item>
          <Form.Item label="Check-In">
            <Input value={selectedRecord?.checkIn} />
          </Form.Item>
          <Form.Item label="Check-Out">
            <Input value={selectedRecord?.checkOut} />
          </Form.Item>
          <Form.Item label="No of Child">
            <Input value={selectedRecord?.noOfChild} />
          </Form.Item>
          <Form.Item label="No of Adult">
            <Input value={selectedRecord?.noOfAdult} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Delete Pool Booking"
        open={isDeleteModalOpen}
        onOk={handleDeleteSubmit}
        onCancel={handleDeleteClose}
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
      <Modal
        title="Payment Pool Booking"
        open={paymentModalOpen}
        footer={[
          <Button key="back" onClick={handlePaymentCancel}>
            Cancel
          </Button>,
          <ReactToPrint
            onBeforePrint={handlePaymentSubmit}
            trigger={() => (
              <Button key="submit" type="primary">
                Ok
              </Button>
            )}
            content={() => targetRef.current}
          />,
        ]}
      >
        <Bill
          discountValue={discountValue}
          setDiscountValue={setDiscountValue}
          targetRef={targetRef}
          barcode={barcode}
          subTotal={selectedRecord?.amount}
          total={selectedRecord?.amount}
          customer={selectedRecord?.createdBy ? selectedRecord?.createdBy?.split(" ")[0] :selectedRecord?.userName }
          tempItem={arrayData}
          discount={checkDiscount()}
        />
      </Modal>
    </div>
  );
};

export default PoolBooking;
