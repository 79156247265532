import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { ApiClient } from "../utils/ApiClient";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUser } from "../store/slices/userSlice";
import toast from "react-hot-toast";

const { Meta } = Card;

export const PoolPackageCards = () => {
  const [oneTimePackages, setOneTimePackages] = useState([]);
  const [basePackages, setBasePackages] = useState([]);
  const [sixMonthPackages, setSixMonthPackages] = useState([]);
  const [twelveMonthPackages, setTwelveMonthPackages] = useState([]);
  const user = useSelector(getUser);

  const navigate = useNavigate();

  const fetchPoolPackages = async () => {
    await ApiClient.get("/pool-packages")
      .then((response) => {
        //console.log(response.data);
        setOneTimePackages(response.data.filter((item) => item.duration < 1));
        setBasePackages(response.data.filter((item) => item.duration == 1));
        setSixMonthPackages(response.data.filter((item) => item.duration == 6));
        setTwelveMonthPackages(
          response.data.filter((item) => item.duration == 12)
        );

      })
      .catch((error) => {
        //console.log(error);
        toast.error("Failed to fetch packages");
      });
  };

  useEffect(() => {
    fetchPoolPackages();
  }, []);

  const handleRegister = async (poolPackageId) => {
    await ApiClient.post("/poolSubscription/", {
      poolPackageId: poolPackageId,
      userId: user._id,
    })
      .then((response) => {
        toast.success("Package Registered Successfully");
        //console.log(response.data);
      })
      .catch((error) => {
        toast.error("Failed to register package");
        //console.log(error);
      });
  };

  return (
    <div className="row w-100 justify-content-center">
      <h3 className="row text-primary my-4 justify-content-center">
        One Time Reservations
      </h3>
      {oneTimePackages &&
        oneTimePackages.sort((a, b) => a.rate - b.rate).map((item, index) => {
          return (
            <div key={index} className="d-flex col-md-3 m-2">
              <Card
                style={{
                  width: "100%",
                  backgroundColor: "#f0f0f0",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                }}
              
              >
                <Meta
                  title={<span style={{  fontSize: "20px", fontWeight: "bold" }}>{item.displayName}</span>}
                  description={`${
                    item.name === "perDayGym"
                      ? "Special discount available for gym memebers!"
                      : ""
                  }. This package is for ${item.pax} member
                  . This package will stay activated for 2 hours.`}
                />
                <div className="w-100 d-flex justify-content-end mt-4">
                  <div>
                    <h5 className="bg-primary text-white p-2 rounded">Rs. {item.rate} /=</h5>
                    {/* {item.name === "perDayGym" && <h6>150 Discount!</h6>} */}
                  </div>
                </div>
              </Card>
            </div>
          );
        })}
      <h3 className="row text-primary my-4 justify-content-center">
        Standard One Month Packages
      </h3>
      {basePackages &&
        basePackages.sort((a, b) => a.rate - b.rate).map((item, index) => {
          return (
            <div key={index} className="d-flex col-md-3 m-2">
              <Card
                style={{
                  width: "100%",
                  backgroundColor: "#f0f0f0",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                }}
                
              >
                <Meta
                  title={<span style={{  fontSize: "20px", fontWeight: "bold" }}>{item.displayName}</span>}
                  description={`Subscription will be activated once you do the payment and it will stay active for ${item.duration} month.`}
                />
                <div className="w-100 d-flex justify-content-end mt-4">
                  <h5 className="bg-primary text-white p-2 rounded">Rs. {item.rate} /=</h5>
                </div>
              </Card>
            </div>
          );
        })}
      <h3 className="row text-primary my-4 justify-content-center">
        Six Month Packages
      </h3>
      {sixMonthPackages &&
        sixMonthPackages.sort((a, b) => a.rate - b.rate).map((item, index) => {
          return (
            <div key={index} className="d-flex col-md-3 m-2">
              <Card
                style={{
                  width: "100%",
                  backgroundColor: "#f0f0f0",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                }}
               
              >
                <Meta
                  title={<span style={{  fontSize: "20px", fontWeight: "bold" }}>{item.displayName}</span>}
                  description={`Subscription will be activated once you do the payment and it will stay active for ${item.duration} month.`}
                />
                <div className="w-100 d-flex justify-content-end mt-4">
                  <h5 className="bg-primary text-white p-2 rounded">Rs. {item.rate} /=</h5>
                </div>
              </Card>
            </div>
          );
        })}
      <h3 className="row text-primary my-4 justify-content-center">
        Twelve Month Packages
      </h3>
      {twelveMonthPackages &&
        twelveMonthPackages.sort((a, b) => a.rate - b.rate).map((item, index) => {
          return (
            <div key={index} className="d-flex col-md-3 m-2">
              <Card
                style={{
                  width: "100%",
                  backgroundColor: "#f0f0f0",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                }}
               
              >
                <Meta
                  title={<span style={{  fontSize: "20px", fontWeight: "bold" }}>{item.displayName}</span>}
                  description={`This package is for ${
                    item.pax
                  } member. Subscription will be activated once you do the payment and it will stay active for ${
                    item.duration
                  } month. ${
                    item.isThreadMill ? " Threadmill is included." : ""
                  }`}
                />
                <div className="w-100 d-flex justify-content-end mt-4">
                  <h5 className="bg-primary text-white p-2 rounded">Rs. {item.rate} /=</h5>
                </div>
              </Card>
            </div>
          );
        })}
    </div>
  );
};
