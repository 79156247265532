import React from "react";
import img from "../assests/img/about.jpg";
import icon from "../assests/img/man.png";
import icon2 from "../assests/img/badge.png";
import { AboutData } from "../components/AboutData";
import { Feature } from "../components/Feature";
import { Trainer } from "../components/Trainer";
import { BMI } from "../components/BMI";
import { WhyChoose } from "../components/WhyChoose";
import { Classes } from "./Classes";
import { GymPackageCard } from "../components/GymPackageCard";

export const Gym = () => {
  return (
    <div>
      <WhyChoose />
      <GymPackageCard />
      {/* <Feature /> */}
      {/* <AboutData /> */}
      <BMI />
      {/* <Trainer /> */}
      {/* <Classes/> */}
    </div>
  );
};
