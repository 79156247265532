import React, { useEffect } from "react";

import img from "../assests/img/about.jpg";
import { Carousel } from "antd";
import img1 from "../assests/img/services/img1.jpg";
import img2 from "../assests/img/services/img2.jpg";
import img3 from "../assests/img/services/img3.jpg";
import img4 from "../assests/img/services/img4.jpg";
import img5 from "../assests/img/services/img5.jpg";
import img6 from "../assests/img/services/img6.jpg";
import img7 from "../assests/img/services/img7.jpg";
import swim from "../assests/img/services/swim.png";
import gym from "../assests/img/services/dumbbell.png";
import badminton from "../assests/img/services/badminton.png";
import billiard from "../assests/img/services/billiard.png";
import gems from "../assests/img/services/gems.png";
import cricket from "../assests/img/services/cricket.png";
import restaurant from "../assests/img/services/restaurant.png";
import WOW from "wowjs";

const images = [img6, img3, img1, img5, img2, img7, img4];
const logos = [gym , swim, cricket, badminton, restaurant, gems, billiard];

const about = [
  {
    title: "Certified GYM Center",
    description:
      "Our Certified GYM Center offers state-of-the-art facilities and expert trainers to help you achieve your fitness goals. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    icon: { gym },
  },
  {
    title: "Swimming Pool",
    description:
      "Dive into relaxation with our luxurious swimming pool. Whether you're seeking a refreshing swim or a tranquil escape, our pool provides the perfect oasis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    icon: { swim },
  },
  {
    title: "Indoor Cricket Court",
    description:
      "Experience the thrill of indoor cricket in our dedicated court. Gather your team and enjoy a spirited game in a comfortable environment, rain or shine. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    icon: { cricket },
  },
  {
    title: "Badminton Court",
    description:
      "Serve, smash, and score on our professional badminton court. Whether you're a beginner or a seasoned player, our court provides the perfect setting for an exhilarating match. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    icon: { badminton },
  },
  {
    title: "Restaurant & Cafe",
    description:
      "Indulge your senses in our exquisite restaurant and cafe. From gourmet meals to artisanal coffee, our culinary offerings cater to every palate. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    icon: { restaurant },
  },
  {
    title: "Gem Store",
    description:
      "Explore a world of elegance and beauty in our gem store. Discover exquisite jewels and gemstones curated to perfection. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    icon: { gems },
  },
  {
    title: "Billiards & Snooker",
    description:
      "Unwind and sharpen your skills in our billiards and snooker lounge. With premium tables and a relaxed ambiance, it's the perfect setting for a friendly game or serious competition. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    icon: { billiard },
  },
];

export const AboutData = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }
  , []);
  return (
    <div>
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-6 wow fadeIn">
            <Carousel autoplay speed={2000} pauseOnHover={false}>
              {images.map((img, index) => (
                <div>
                  <img src={img} alt="img" style={{ width: "100%" }} />
                </div>
              ))}
            </Carousel>
          </div>
          <div className="col-lg-6">
            <h2 className="display-4 font-weight-bold mb-4 wow slideInRight">
              Welcome to Our Fitness Center
            </h2>
            <p className="wow slideInRight">
              Our Fitness Center offers a wide range of facilities and services
              to help you achieve your fitness goals. Whether you're looking to
              build muscle, lose weight, or improve your overall health, we have
              everything you need to succeed.
            </p>
            {/* <img src={swim} alt="icon" style={{ width: "50px" }} /> */}
            <Carousel autoplay speed={2000} pauseOnHover={false}>
              {about.map((data, index) => (
                <div>
                  <div className="d-flex align-items-center">
                  <img src={logos[index]} alt="icon" className="text-primary wow pulse infinite m-2" style={{ width: "100px" }} />
                    <div className="ml-3">
                      <h3 className="text-primary">{data.title}</h3>
                      <p>{data.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
            <a href="" className="btn btn-lg px-4 btn-outline-primary wow fadeInUp">
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
