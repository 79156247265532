import React, { useEffect, useState } from "react";
import { FoodItem } from "../components/FoodItem";
import burger from "../assests/img/restaurant/burger.jpg";
import rice from "../assests/img/restaurant/rice.jpg";
import noodles from "../assests/img/restaurant/noodles.jpg";
import bg from "../assests/img/bg-hero.jpg";
import "./Restaurant.css";
import { RestaurantAbout } from "../components/RestaurantAbout";
import { RestaurantService } from "../components/RestaurantService";
import hero from "../assests/img/hero.png";
import { ApiClient } from "../utils/ApiClient";
import toast from "react-hot-toast";

export const Restaurant = () => {
  const [items, setItems] = useState([]);
  const [active, setActive] = useState();
  const onChange = (name) => {
    setActive(name);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchDataItems();
  }, []);

  const fetchDataItems = async () => {
    try {
      const response = await ApiClient.get("/item/");
      //console.log(response.data);
      setItems(response.data);
    } catch (error) {
      // console.error(error);
      toast.error("Failed to fetch items");
    }
  };

  const [category, setCategory] = useState([]);

  const fetchData = async () => {
    try {
      const response = await ApiClient.get("/category/");
      //console.log(response.data);
      setCategory(response.data); 
    } catch (error) {
      // console.error(error);
      toast.error("Failed to fetch categories");
    }
  };

  return (
    <div className="w-100">
      <RestaurantAbout />
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h5 className="section-title ff-secondary text-center text-primary fw-normal">
          Food Menu
        </h5>
        <h1 className="mb-5">Most Popular Items</h1>
      </div>
      <div
        className="tab-className text-center wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <ul className="nav nav-pills d-inline-flex gap-2 justify-content-center border-bottom mb-5">
          {category?.map((item, index) => {
            return (
              <li
                className="nav-item"
                key={index}
                onClick={() => onChange(item.name)}
              >
                <a
                  className="d-flex align-items-center text-start mx-3 me-0 pb-3"
                  data-bs-toggle="pill"
                  href={`#tab-${index + 1}`}
                  style={{ textDecoration: "none" }} 
                >
                  <i className={`${item.icon} fa-2x text-primary`}></i> 
                  <div className="ps-3">
                    <small className="text-body">{item.specific}</small>
                    <h6 className="mt-n1 mb-0 text-primary">{item.name}</h6>
                  </div>
                </a>
              </li> 
            );
          })}
        </ul>
      </div>
      <div
        className=" food-back "
         
        style={{ backgroundImage: bg, zIndex: 1000, minHeight: "75vh"}}
      >
        <div className="d-flex  justify-content-center">
          <div className=" row g-4  container d-flex mt-2 mb-4">
            {items.map((item, index) => {
              return (
                item.category === active && (
                  <div className="col-md-6 mb-3 " key={index}>
                    <FoodItem data={item} index={index} />
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
      <RestaurantService />
      <div className=" py-5 food-back hero-header mb-1">
        <div className="my-5 py-5 container">
          <div className="  row align-items-center g-5">
            <div className="col-lg-6 text-center text-lg-start">
              <h1 className="display-3 text-white animated slideInLeft m-4">
                Enjoy Our
                <br />
                Delicious Meal
              </h1>
              <p className="text-white animated slideInLeft mb-4 pb-2 m-4">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit.
                Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit,
                sed stet lorem sit clita duo justo magna dolore erat amet
              </p>
              <a
                href=""
                className="btn btn-primary py-sm-3 px-sm-5 me-3 animated slideInLeft m-4"
              >
                Book Your Meal
              </a>
            </div>
            <div className="col-lg-6 text-center text-lg-end overflow-hidden">
              <img className="img-fluid rotate-image" src={hero} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
