import React from "react";
import { GymPackageCard } from "./GymPackageCard";

export const GymPackages = () => {
  return (
    <div className="container">
      <GymPackageCard />
    </div>
  );
};
