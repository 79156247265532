import React from "react";
import { PoolPackageCards } from "./PoolPackageCards";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

export const PoolPackages = () => {
  const Navigate = useNavigate();
  const handleClick = () => {
    //navigate to /calendar
    Navigate("/booking/swimmingPool/calendar");
  };
  return (
    <div className="">
      <div className="d-flex justify-content-center">
        <Button
          type="primary"
          onClick={() => {
            handleClick();
          }}
        >
          Book Now
        </Button>
      </div>

      <PoolPackageCards />
    </div>
  );
};
