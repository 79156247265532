import React from 'react'
import Sidebar from '../components/Dashboard/Sidebar'

export const Dashboard = () => {
  return (
    <div>
      <Sidebar /> 
    </div>
  )
}
