import React from "react";
import { Select, DatePicker } from "antd";

const Filter = ({
  usageStatuses,
  paymentStatuses,
  setSelectedUsageStatus,
  setSelectedPaymentStatus,
  setSelectedDate,
  selectedDate,
}) => {
  return (
    <div>
      {" "}
      <div className="filter-field row gap-2">
        <div className="col-md">
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Please select"
            defaultValue={[
              "Pending",
              "Check-In",
              "Check-Out",
              "Cancelled",
              "Closed",
            ]}
            onChange={(value) => {
              setSelectedUsageStatus(value);
            }}
            options={usageStatuses}
          />
        </div>
        <div className="col-md">
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Please select"
            defaultValue={["Pending", "Paid"]}
            onChange={(value) => {
              setSelectedPaymentStatus(value);
            }}
            options={paymentStatuses}
          />
        </div>
        <div className="col-md">
          <DatePicker
            onChange={(date, dateString) => {
              setSelectedDate(date);
              // console.log(date, dateString);
            }}
            format={"YYYY-MMM-DD"}
            defaultValue={selectedDate}
          />
        </div>
      </div>
    </div>
  );
};

export default Filter;
