import React, { useEffect, useState } from "react";
import { Button, Input, Table } from "antd";
import { ApiClient } from "../../utils/ApiClient";
import { DatePicker } from "antd";
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";
import moment from "moment";
import { render } from "@testing-library/react";
import { TfiReload } from "react-icons/tfi";
import { Modal, Form, Row, Col } from "antd";


export const Analytics = () => {
  const [reason, setReason] = useState("");
  const [recordID, setRecordID] = useState("");
  const [open, setOpen] = useState(false);
  const [componentSize, setComponentSize] = useState("large");

  const onFormLayoutChange = ({ size }) => {
    setComponentSize("large");
  };

  const handleDelete = async ()  => {
    try {
      const response = await ApiClient.delete(`/payment/${recordID}`,{
        reason: reason
      });
      toast.success("Admin will delete the payment soon!");
      fetchPayments(selectedDate);
    } catch (error) {
      toast.error("Failed to delete payment");
    }
  };

  const handleSubmit = () => {
    handleDelete();
    setOpen(false);
    setReason("");
  };

  const [payments, setPayments] = useState([]);
  const [income, setIncome] = useState(0);

  const [isloading, setIsLoading] = useState(false);

  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    fetchPayments(today);
  }, []);

  const [selectedDate, setSelectedDate] = useState(today);

  const onChange = (date,dateString) => {
    setSelectedDate(dateString);

    fetchPayments(dateString);
  };

  const fetchPayments = async (date) => {
    setIsLoading(true);
    try {
      const response = await ApiClient.get(`/payment/date/${date}`);
      setPayments(response.data);
      setIsLoading(false);
      setIncome(response.data.reduce((acc, curr) => acc + curr.balance, 0));
    } catch (error) {
      // console.error(error);
      setIsLoading(false);
      toast.error("Failed to fetch payments");
    }
  };

  const columns = [
    {
      title: "Customer",
      dataIndex: ["userId", "name"],
      key: "name",
      render: (text, record) => {
        return (
          record.customerName !== undefined
            ? record?.customerName?.split(" ")[0]
            : record.userName
        )?.split(" ")[0];
      },
    },

    {
      title: "Invoice No",
      dataIndex: "barcode",
      key: "barcode",
    },
    {
      title: "Payment Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => new Date(date).toLocaleTimeString(),
    },
    {
      title: "Cashier",
      dataIndex: ["cashierId", "name"],
      key: "name",
      render: (text, record) => {
        return record.cashierId.name?.split(" ")[0];
      },
    },
    {
      title: "Total",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => <span>{`Rs.${record.amount}/=`}</span>,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (text, record) => (
        <span>{record.discount === 0 ? "0" : `Rs. ${record.discount}/=`}</span>
      ),
    },
    {
      title: "Fine",
      dataIndex: "fine",
      key: "fine",
      render: (text, record) => (
        <span>{record.fine === 0 ? "0" : `Rs. ${record.fine}/=`}</span>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => <span>{`Rs.${record.balance}/=`}</span>,
    },
    {
      title: "Status",
      dataIndex: "paymentCategory",
      key: "paymentCategory",
      render: (text, record) => (text === "Other" ? record.reason : text),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text, record) => (
    //     <div>
    //       <MdDelete  
    //       onClick={() => {setRecordID(record._id);
    //         setOpen(true);
    //       }}
    //       style={{
    //         scale: "1.5",
    //       }} />
    //     </div>
    //   ),
    // }
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ fontWeight: "bold", marginRight: "10px" }}>
            Date:
          </label>
          <DatePicker
            style={{ width: "200px" }} // Adjust the width as needed
            onChange={onChange}
            disabledDate={(current) => current && current > moment()}
          />
        </div>
        <div>
          <Button type="primary" onClick={()=>fetchPayments(today)}>
            <TfiReload />
          </Button>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <label style={{ fontWeight: "bold", marginRight: "10px" }}>
            Total:
          </label>
          <input
            style={{
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "16px",
            }}
            type="text"
            value={`Rs.${income}/=`}
            readOnly
          />
        </div>
      </div>
      <Table dataSource={payments} columns={columns} loading={isloading} scroll={{x:1300}}/>
      <Modal
        title={`Delete Payment under Admin Approval`}
        centered
        open={open}
        onOk={() => handleSubmit()}
        okButtonProps={{
          disabled: !reason,
        }}
        onCancel={() => {
          setOpen(false);
          setReason("");
        }}
      >
        <div>
          <Row justify="center">
            <Col span={24} offset={0} className="w-100">
              <Form
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 18,
                }}
                layout="horizontal"
                initialValues={{
                  size: componentSize,
                }}
                onValuesChange={onFormLayoutChange}
                size={componentSize}
                labelAlign="left"
                style={{
                  width: "100%",
                  // maxWidth: 600,
                }}
              >
                <Form.Item label="Reason">
                  <Input
                    style={{ width: "100%" }}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}                    
                  />
                </Form.Item>

              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};
