import { Input, Tag } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "antd";
import { ApiClient } from "../../utils/ApiClient";
import toast from "react-hot-toast";

const CheckOutModal = ({ open, setOpen, record, fetchData }) => {
  //   const checkedInList = record?.checkedInList;
  const [checkOutList, setCheckedOutList] = useState([]);
  const [input, setInput] = useState();
  const [checkedInList, setCheckedInList] = useState([]);



  useEffect(() => {
    setCheckedInList(record?.checkedInList);
  }, [record]);

  const handleChange = (e) => {
    setInput(e.target.value);
    if (e.target.value?.length > 7) {
      //console.log(e.target.value);
      //console.log(record?.checkedInList?.includes(Number(e.target.value)));
      if (
        record?.checkedInList?.includes(Number(e.target.value)) &&
        !checkOutList?.includes(Number(e.target.value))
      ) {
        setCheckedOutList((prev) => [...prev, Number(e.target.value)]);
        setInput(null);
      } else {
        setInput(null);
      }
    }
  };

  const handleClose = () => {
    setInput(null);
    setOpen(false);
    setCheckedOutList([]);
  };

  const handleSubmit = async () => {
    try {
      const response = await ApiClient.post(
        `/pool-event/check-out/${record?._id}`,
        {
            checkedOutList: checkedInList,
        }
      );
      //console.log(response);
      fetchData();
      toast.success("Checked Out Successfully");
    } catch (error) {
      //console.log(error);
      toast.error("Error Checking Out");
    }
    setOpen(false);
  };
  return (
    <div>
      <>
        <Modal
          title="Check-Out"
          open={open}
          onOk={() => handleSubmit()}
          onCancel={() => handleClose()}
          okButtonProps={{
            // disabled: checkOutList?.length < checkedInList?.length,
          }}
        >
          <div>
            <h6>Created By: {record?.createdBy}</h6>
            <h6>Date : {record?.date}</h6>
            <h6>Check-In : {record?.checkIn}</h6>
            <h6>Check-Out : {record?.checkOut}</h6>
            <h6>Number of Adults : {record?.noOfAdult}</h6>
            <h6>Number of Children : {record?.noOfChild}</h6>

            <Input
              type="number"
              placeholder="Scan QR"
              onChange={(e) => handleChange(e)}
              value={input}
              disabled={
                checkOutList?.length >= record?.noOfAdult + record?.noOfChild
              }
            ></Input>

            <div className="my-2">
              {record?.checkedInList?.map((item) => {
                return (
                  <Tag
                    key={item}
                    color={`${checkOutList.includes(item) ? "green" : "blue"}`}
                    // color="blue"
                  >
                    {item}
                  </Tag>
                );
              })}
            </div>
          </div>
        </Modal>
      </>
    </div>
  );
};

export default CheckOutModal;
